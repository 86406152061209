import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
  Modal,
  Button,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { colors } from '../../../../../themes/theme';

const InlineEditableField = ({ value, onSave, disabled }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const [isSaving, setIsSaving] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Helper to show snackbar
  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  // Handle save logic
  const handleSave = async () => {
    if (!inputValue.trim()) {
      showSnackbar('Input value cannot be empty.', 'warning');
      return;
    }
    if (inputValue.trim() === value) {
      showSnackbar('No changes made.', 'info');
      setIsEditing(false);
      return;
    }

    setIsSaving(true);
    try {
      await onSave(inputValue.trim());
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving custom name:', error);
      showSnackbar('Failed to save. Please try again.', 'error');
    } finally {
      setIsSaving(false);
    }
  };

  // Handle cancel logic
  const handleCancel = () => {
    setInputValue(value);
    setIsEditing(false);
  };

  // Close snackbar
  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <>
      {!isSmallScreen && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            opacity: disabled ? 0.6 : 1,
            pointerEvents: disabled ? 'none' : 'auto',
          }}
        >
          {isEditing && !disabled ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                width: '100%',
                backgroundColor: colors.light,
                borderRadius: 1,
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                p: 1,
                transition: 'border-color 0.3s',
              }}
            >
              <TextField
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                size="small"
                disabled={isSaving}
                sx={{
                  width: '100%',
                  fontSize: '0.9rem',
                  '& .MuiInputBase-root': {
                    fontSize: '0.9rem',
                  },
                }}
                autoFocus
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleSave();
                  if (e.key === 'Escape') handleCancel();
                }}
              />
              <Tooltip title="Save" arrow>
                <span>
                  <IconButton
                    onClick={handleSave}
                    size="small"
                    color="success"
                    disabled={isSaving}
                  >
                    <CheckIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Cancel" arrow>
                <IconButton onClick={handleCancel} size="small" color="error">
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Box>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                cursor: 'pointer',
                '&:hover': {
                  color: colors.BorderLine,
                },
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: { xs: '0.85rem', sm: '1rem' },
                  color: 'text.primary',
                  transition: 'color 0.3s',
                  cursor: disabled ? 'default' : 'pointer',
                }}
                onClick={() => !disabled && setIsEditing(true)}
              >
                {value}
              </Typography>
              <Tooltip title="Edit" arrow>
                <IconButton onClick={() => setIsEditing(true)} size="small">
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
      )}

      {isSmallScreen && (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              '&:hover': {
                color: colors.BorderLine,
              },
            }}
            onClick={() => !disabled && setIsEditing(true)}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: { xs: '0.80rem', sm: '1rem' },
                color: 'text.primary',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                maxWidth: '100px',
              }}
            >
              {value}
            </Typography>
            <Tooltip title="Edit" arrow>
              <IconButton
                size="small"
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: { xs: '1rem', sm: '1.2rem' },
                  },
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <Modal
            open={isEditing}
            onClose={handleCancel}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                bgcolor: 'background.paper',
                p: 2,
                borderRadius: 2,
                boxShadow: 24,
                maxWidth: '90%',
                width: 300,
                textAlign: 'center',
              }}
            >
              <Typography variant="h6" sx={{ mb: 2 }}>
                Edit Value
              </Typography>
              <TextField
                fullWidth
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                autoFocus
                sx={{ mb: 3,  }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<CheckIcon />}
                  onClick={handleSave}
                  sx={{
                    borderRadius: '20px',
                    textTransform: 'none',
                    fontWeight: 'bold',
                    fontSize: '0.9rem',
                    '&:hover': {
                      backgroundColor: colors.success,
                    },
                  }}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<CloseIcon />}
                  onClick={handleCancel}
                  sx={{
                    borderRadius: '20px',
                    textTransform: 'none',
                    fontWeight: 'bold',
                    fontSize: '0.9rem',
                    borderColor: colors.error,
                    '&:hover': {
                      backgroundColor: colors.error,
                      color: colors.white,
                    },
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      )}

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default InlineEditableField;
