import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HTRTabs, HTRTab } from '../../styles/style';
import HomeIcon from '@mui/icons-material/Home';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import NavigationWarning from '../Data Grid/NavigationWarning';
import { DASHBOARD_ROUTES } from '../../../data/constants';
import { store } from '../../../data/store';
import { useStore } from '@tanstack/react-store';

export function NavigationTabs() {
  const location = useLocation();
  const isDatagridPath = location.pathname === '/dashboard/datagrid';
  const [showWarning, setShowWarning] = useState(false);
  // Get rowEditCount from the store
  const { rowEditCount } = useStore(store, (state) => ({
    rowEditCount: state.rowEditCount,
  }));

  const isEditing = rowEditCount > 0;
  // Set initial tab value based on location
  const initialTabValue = DASHBOARD_ROUTES.includes(location.pathname)
    ? location.pathname
    : DASHBOARD_ROUTES[0];
  const [value, setValue] = useState(initialTabValue);
  // Update selected tab if the location changes
  useEffect(() => {
    setValue(initialTabValue);
  }, [initialTabValue]);
  // Handle tab change
  const handleChange = (event, newValue) => {
    if (!isEditing) {
      setValue(newValue);
    }
  };
  // Handle tab click with warning for unsaved changes
  const handleTabClick = (event, route) => {
    if (isDatagridPath && isEditing && route !== value) {
      event.preventDefault();
      event.stopPropagation();
      setShowWarning(true);
    }
  };
  // Generate props for each tab
  const getTabProps = (route) => ({
    component: Link,
    to: route,
    value: route,
    disabled: isDatagridPath && isEditing && route !== value,
    sx: {
      '@media (max-width: 374px)': {
        width: '100px',
      },
      '&.Mui-disabled': {
        pointerEvents: 'auto',
        color: 'text.disabled',
        opacity: 0.7,
      },
      '&.Mui-selected': {
        color: 'primary.white',
        opacity: 1,
      },
    },
    onClickCapture: (e) => handleTabClick(e, route),
  });

  return (
    <>
      <HTRTabs
        value={value}
        onChange={handleChange}
        centered
        sx={{
          '& .MuiTabs-indicator': {
            visibility: 'visible',
          },
        }}
      >
        <HTRTab
          icon={<HomeIcon />}
          label="Home"
          {...getTabProps(DASHBOARD_ROUTES[0])}
        />
        <HTRTab
          icon={<ImportExportOutlinedIcon />}
          label="Transactions"
          {...getTabProps(DASHBOARD_ROUTES[1])}
        />
        <HTRTab
          icon={<AssignmentOutlinedIcon />}
          label="Reports"
          {...getTabProps(DASHBOARD_ROUTES[2])}
        />
      </HTRTabs>
      {/* Show warning dialog when trying to navigate during editing */}
      <NavigationWarning
        isEditing={showWarning}
        onClose={() => setShowWarning(false)}
      />
    </>
  );
}
