import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  Typography,
  Box,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Grid,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Receipt as ReceiptIcon,
  ExpandLess,
  ExpandMore,
  AccountBalance,
  LocalOffer,
} from '@mui/icons-material';
import dayjs from 'dayjs';
import { colors } from '../../../../themes/theme';

const formatCategory = (category) => {
  if (!category) {
    return 'Unknown Category';
  }
  return category
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const MobileTrxList = ({
  transactions,
  onEdit,
  onDelete,
  onUploadReceipt,
  transactionCategoryColorMap,
}) => {
  const [openItemId, setOpenItemId] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [transactionToDelete, setTransactionToDelete] = useState(null);

  const handleToggle = (id) => {
    setOpenItemId(openItemId === id ? null : id);
  };

  const handleDeleteClick = (transaction) => {
    setTransactionToDelete(transaction);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (transactionToDelete) {
      onDelete(transactionToDelete.id);
    }
    setDeleteConfirmOpen(false);
    setTransactionToDelete(null);
  };

  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setTransactionToDelete(null);
  };

  const buttonSx = {
    borderRadius: '100px',
    borderColor: 'divider',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    px: 2,
    py: 0.5,
    '& .MuiTypography-caption': {
      mt: 0.25,
      '@media (max-width: 374px)': {
        fontSize: '0.65rem',
      },
      textTransform: 'capitalize',
    },
  };

  if (transactions.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 2,
          p: 3,
          backgroundColor: 'background.paper',
          textAlign: 'center',
          height: '100%',
        }}
      >
        <Typography variant="h6" color="text.secondary">
          No transactions found
        </Typography>
        <Typography variant="body2" color="text.secondary">
          No transactions available for this period or filter.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <List
        sx={{
          width: '100%',
          bgcolor: 'background.paper',
          height: 'calc(100vh - 150px)',
          flexGrow: 1,
          overflowY: 'auto',
        }}
      >
        {transactions.map((transaction) => (
          <React.Fragment key={transaction.id}>
            <ListItem
              button
              onClick={() => handleToggle(transaction.id)}
              sx={{
                borderLeft: '4px solid',
                borderLeftColor:
                  transaction.amount >= 0 ? 'success.main' : 'error.main',
              }}
            >
              <ListItemText
                primary={
                  <Typography variant="subtitle1" component="div">
                    {transaction.description || 'No Description'}
                  </Typography>
                }
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      color={
                        transaction.amount >= 0 ? 'success.main' : 'error.main'
                      }
                    >
                      {transaction.amount < 0 ? '-' : ''}$
                      {Math.abs(transaction.amount).toFixed(2)}
                    </Typography>
                    {' • '}
                    <Typography
                      component="span"
                      variant="body2"
                      color="text.secondary"
                    >
                      {dayjs(transaction.date).format('MMM D, YYYY')}
                    </Typography>
                  </React.Fragment>
                }
              />
              {openItemId === transaction.id ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              in={openItemId === transaction.id}
              timeout="auto"
              unmountOnExit
            >
              <Box
                sx={{
                  p: 2,
                  pl: 4,
                  bgcolor: 'background.paper',
                  border: '1px solid',
                  borderColor: 'divider',
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                      <AccountBalance sx={{ mr: 1, color: 'text.secondary' }} />
                      <Typography variant="body1">
                        Account - {transaction.account}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                      <LocalOffer sx={{ mr: 1, color: 'text.secondary' }} />
                      <span
                        style={{
                          backgroundColor:
                            transactionCategoryColorMap[transaction.category],
                          padding: '5px 10px',
                          borderRadius: '100px',
                          fontWeight: 'medium',
                          fontSize: '12px',
                        }}
                      >
                        {formatCategory(transaction.category)}
                      </span>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      gap={2}
                      justifyContent="space-between"
                      mt={1}
                    >
                      <Button
                        aria-label="edit"
                        onClick={() => onEdit(transaction.id)}
                        sx={{
                          ...buttonSx,
                          backgroundColor: colors.primary,
                          color: 'white',
                        }}
                      >
                        <EditIcon
                          sx={{ mr: 0.5, width: '16px', height: '16px' }}
                        />
                        <Typography variant="caption">Edit</Typography>
                      </Button>
                      <Button
                        aria-label="delete"
                        onClick={() => handleDeleteClick(transaction.id)}
                        sx={{
                          ...buttonSx,
                          backgroundColor: colors.expenses,
                          color: 'white',
                        }}
                      >
                        <DeleteIcon
                          sx={{ mr: 0.5, width: '16px', height: '16px' }}
                        />
                        <Typography variant="caption">Delete</Typography>
                      </Button>
                      <Button
                        aria-label="receipt"
                        onClick={() => onUploadReceipt(transaction.id)}
                        sx={{
                          ...buttonSx,
                          backgroundColor: colors.black,
                          color: 'white',
                        }}
                      >
                        <ReceiptIcon
                          sx={{ mr: 0.5, width: '16px', height: '16px' }}
                        />
                        <Typography variant="caption">Receipt</Typography>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
            <Divider />
          </React.Fragment>
        ))}
      </List>

      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Confirm Delete'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this transaction? This action cannot
            be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MobileTrxList;
