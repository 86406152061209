import React from 'react';

import { Box } from '@mui/material';

import { SGridToolbarQuickFilter } from '../../styles/style.js';

function QuickSearchToolbar() {
  return (
    <Box sx={{ width: '100%', padding: '0' }}>
      <SGridToolbarQuickFilter
        quickFilterProps={{
          placeholder: 'Search transactions...',
          variant: 'standard',
          InputProps: {
            disableUnderline: true,
          },
        }}
        sx={{
          '& .MuiInput-underline:before': {
            display: 'none',
          },
          '& .MuiInput-underline:after': {
            display: 'none',
          },
        }}
      />
    </Box>
  );
}

export default QuickSearchToolbar;
