import ReactGA from 'react-ga4';
import React, { useContext, useState, useEffect, useCallback } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  IconButton,
  Collapse,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  AccountCircle,
  AccountBalance,
  Menu as MenuIcon,
  Close as CloseIcon,
  Dashboard as DashboardIcon,
} from '@mui/icons-material';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { AuthContext } from '../../../../components/common/contexts/authContext';
import logToSentry from '../../../../lib/sentry';
import ProfileInfo from './ProfileInfo';
import BankAccountInfo from './BankAccountPage/BankAccountInfo';
import { colors } from '../../../../themes/theme';

const SettingsPage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn } = useContext(AuthContext);

  const [selectedTab, setSelectedTab] = useState(() => {
    if (location.pathname.includes('profile')) return 1;
    if (location.pathname.includes('bank-account')) return 2;
    return 0;
  });

  const getPageTitle = useCallback(() => {
    switch (location.pathname) {
      case '/settings/profile':
        return 'Profile';
      case '/settings/bank-account':
        return 'Bank Accounts';
      default:
        return 'Settings';
    }
  }, [location.pathname]);

  useEffect(() => {
    const currentPageTitle = getPageTitle();
    ReactGA.event({
      category: 'Settings',
      action: 'Page View',
      label: currentPageTitle,
    });
    logToSentry('settings_page_view', {
      tags: {
        category: 'settings',
        page: currentPageTitle,
        path: location?.pathname,
      },
    });
  }, [location.pathname, getPageTitle]);

  const handleTabChange = (event, newValue) => {
    let tabName;
    if (newValue === 1) tabName = 'Profile';
    else if (newValue === 2) tabName = 'Bank Accounts';
    else tabName = 'Dashboard';

    ReactGA.event({
      category: 'Settings',
      action: 'Tab Change',
      label: tabName,
    });
    logToSentry('settings_tab_change', {
      tags: {
        category: 'settings',
        tab: tabName,
        previousTab:
          selectedTab === 1
            ? 'Profile'
            : selectedTab === 2
              ? 'Bank Accounts'
              : 'Dashboard',
      },
    });

    setSelectedTab(newValue);
    if (newValue === 0) {
      navigate('/dashboard');
    } else if (newValue === 1) {
      navigate('/settings/profile');
    } else if (newValue === 2) {
      navigate('/settings/bank-account');
    }
    setMobileOpen(false);
  };

  if (!isLoggedIn) {
    ReactGA.event({
      category: 'Settings',
      action: 'Unauthorized Access Attempt',
    });
    logToSentry('settings_unauthorized_access', {
      status: 'error',
      tags: {
        category: 'settings',
        path: location?.pathname,
      },
    });
    return <Navigate to="/login" />;
  }

  const NavigationMenu = () => (
    <List sx={{ width: '100%', bgcolor: 'white', p: 1}}>
      <ListItem
        button
        selected={selectedTab === 0}
        onClick={() => handleTabChange(null, 0)}
        sx={{
          borderRadius: 1,
          mb: 1,
          '&.Mui-selected': {
            bgcolor: 'transparent',
          },
          '&:hover': {
            bgcolor: colors.lightGray2,
          },
        }}
      >
        <ListItemIcon sx={{ minWidth: 40 }}>
          <DashboardIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText
          primary="Dashboard"
          primaryTypographyProps={{
            fontSize: '1rem',
            fontWeight: 500,
          }}
        />
      </ListItem>

      <ListItem
        button
        selected={selectedTab === 1}
        onClick={() => handleTabChange(null, 1)}
        sx={{
          borderRadius: 1,
          mb: 1,
          bgcolor: selectedTab === 1 ? 'primary.main' : 'transparent',
          color: selectedTab === 1 ? 'white' : 'inherit',
          '&.Mui-selected': {
            bgcolor: colors.primary,
            '&:hover': {
              bgcolor: colors.primaryDark,
            },
          },
          '&:hover': {
            bgcolor: colors.lightGray2,
          },
        }}
      >
        <ListItemIcon
          sx={{ minWidth: 40, color: selectedTab === 1 ? 'white' : 'inherit' }}
        >
          <AccountCircle fontSize="small" />
        </ListItemIcon>
        <ListItemText
          primary="Profile"
          primaryTypographyProps={{
            fontSize: '1rem',
            fontWeight: 500,
          }}
        />
      </ListItem>

      <ListItem
        button
        selected={selectedTab === 2}
        onClick={() => handleTabChange(null, 2)}
        sx={{
          borderRadius: 1,
          '&.Mui-selected': {
            bgcolor: colors.primary,
            color: 'white',
            '&:hover': {
              bgcolor: colors.primaryDark,
            },
          },
          '&:hover': {
            bgcolor: selectedTab === 2 ? colors.primaryDark : colors.lightGray2,
          },
        }}
      >
        <ListItemIcon
          sx={{ minWidth: 40, color: selectedTab === 2 ? 'white' : 'inherit' }}
        >
          <AccountBalance fontSize="small" />
        </ListItemIcon>
        <ListItemText
          primary="Bank Accounts"
          primaryTypographyProps={{
            fontSize: '1rem',
            fontWeight: 500,
          }}
        />
      </ListItem>
    </List>
  );

  return (
    <Box sx={{ width: '100%' }}>
      {/* Mobile Header */}
      {isSmallScreen && (
        <Box
          sx={{
            bgcolor: 'white',
            p: 1,
            borderBottom: 1,
            borderColor: 'divider',
            position: 'relative',
            zIndex: 1100,
          }}
        >
          <IconButton
            onClick={() => setMobileOpen(!mobileOpen)}
            edge="start"
            sx={{ mr: 2 }}
          >
            {mobileOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          <Typography
            component="span"
            sx={{
              fontSize: '1.5rem',
              fontWeight: 700,
              color: colors.black,
            }}
          >
            {getPageTitle()}
          </Typography>
        </Box>
      )}

      <Box sx={{ display: 'flex', position: 'relative' }}>
        {/* Desktop Title and Main Content Container */}
        {!isSmallScreen && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              px: 2,
              py: 3,
              zIndex: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              {getPageTitle()}
            </Typography>
          </Box>
        )}

        {/* Navigation Menu */}
        {isSmallScreen ? (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 1000,
              height: '100%',
              overflow: 'hidden',
            }}
          >
            <Collapse
              in={mobileOpen}
              orientation="horizontal"
              sx={{
                height: '100%',
                '& .MuiCollapse-wrapperInner': {
                  width: '280px',
                },
              }}
            >
              <Box
                sx={{
                  width: 280,
                  bgcolor: 'white',
                  boxShadow: 3,
                  height: '100%',
                }}
              >
                <NavigationMenu />
              </Box>
            </Collapse>
          </Box>
        ) : (
          <Box
            sx={{
              width: 280,
              bgcolor: 'white',
              borderRadius: 1,
              mt: 8,
            }}
          >
            <NavigationMenu />
          </Box>
        )}

        {/* Main Content */}
        <Box
          component="main"
          sx={{
            flex: 1,
            ml: { xs: 0, md: 2 },
            mt: { xs: 0, md: 8 },
          }}
        >
          <Box sx={{ bgcolor: 'white', borderRadius: 1, p: { xs: 1, md: 3 } }}>
            <Routes>
              <Route path="/" element={<Navigate to="profile" replace />} />
              <Route path="profile" element={<ProfileInfo />} />
              <Route path="bank-account" element={<BankAccountInfo />} />
            </Routes>
          </Box>
        </Box>

        {/* Overlay when mobile menu is open */}
        {isSmallScreen && mobileOpen && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              bgcolor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 999,
            }}
            onClick={() => setMobileOpen(false)}
          />
        )}
      </Box>
    </Box>
  );
};

export default SettingsPage;
