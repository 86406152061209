import React, { useState, useEffect } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { colors, pieChart } from '../../../../themes/theme';
import { groupedCategories } from './ExpenseConstants';
import useWindowSize from '../../../common/hooks/useWindowSize';

// Get the CanvasJSChart component and CanvasJS object
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJS = CanvasJSReact.CanvasJS;

dayjs.extend(isBetween);

const { pieChartColors } = pieChart;

// Create a map of category values to their labels and abbreviations
const groupMap = Object.entries(groupedCategories).reduce(
  (acc, [groupName, categories]) => {
    categories.forEach((category) => {
      acc[category.value] = groupName;
    });
    return acc;
  },
  {}
);

// Format number with custom rounding down
const formatNumber = (num) => {
  if (num >= 1000000) {
    const millions = Math.floor((num / 1000000) * 10) / 10;
    return `$${millions.toFixed(1)}m`;
  } else if (num >= 1000) {
    const thousands = Math.floor((num / 1000) * 10) / 10;
    return `$${thousands.toFixed(1)}k`;
  } else if (num >= 100) {
    return `$${Math.floor(num).toFixed(0)}`;
  } else {
    return `$${num.toFixed(2)}`;
  }
};

const PieChart = ({
  transactions,
  timePeriod,
  selectedWeeks,
  selectedMonths,
  selectedQuarters,
  selectedYears,
  startDate,
  endDate,
}) => {
  const [data, setData] = useState([]);
  const [explodedIndex, setExplodedIndex] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [externalLabel, setExternalLabel] = useState('Total Expenses');
  const { width } = useWindowSize();

  // Format start and end dates in a user-friendly way
  const formattedStartDate = dayjs(startDate).format('MMM D, YYYY');
  const formattedEndDate = dayjs(endDate).format('MMM D, YYYY');

  useEffect(() => {
    const filteredTransactions = transactions.filter((transaction) => {
      const transactionDate = dayjs(
        transaction.authorized_date || transaction.date
      ).local();
      const transactionMonthStr = transactionDate.format('MMMM YYYY');
      const transactionQuarterStr = `Q${
        Math.floor(transactionDate.month() / 3) + 1
      } ${transactionDate.year()}`;
      const transactionYearStr = transactionDate.format('YYYY');
      switch (timePeriod) {
        case 'week':
          return selectedWeeks.some((week) => {
            const [start, end] = week.split(' - ');
            const year = end.split(', ')[1];
            const startDate = dayjs(`${start}, ${year}`).startOf('day');
            const endDate = dayjs(end).endOf('day');
            return transactionDate.isBetween(startDate, endDate, null, '[]');
          });
        case 'month':
          return selectedMonths.includes(transactionMonthStr);
        case 'quarter':
          return selectedQuarters.includes(transactionQuarterStr);
        case 'year':
          return selectedYears.includes(transactionYearStr);
        default:
          if (startDate && endDate) {
            const start = dayjs(startDate).startOf('day');
            const end = dayjs(endDate).endOf('day');
            return transactionDate.isBetween(start, end, null, '[]');
          }
          return true;
      }
    });

    // Calculate total amounts for each expense category
    const groupTotals = filteredTransactions.reduce((acc, transaction) => {
      const rawCategory = transaction.category?.[0] || 'UNCATEGORIZED_EXPENSE';
      const categoryKey = rawCategory.toUpperCase().replace(/ /g, '_');
      const groupName =
        groupMap[categoryKey] || 'Entertainment & Miscellaneous';
      const amount = parseFloat(transaction.amount);
      if (amount < 0) {
        acc[groupName] = (acc[groupName] || 0) + Math.abs(amount);
      }
      return acc;
    }, {});

    const totalExpenses = Object.values(groupTotals).reduce(
      (sum, val) => sum + val,
      0
    );

    // Sort categories by amount before mapping to data points
    const sortedGroups = Object.entries(groupTotals).sort(
      ([, a], [, b]) => b - a
    );

    const newData = sortedGroups.map(([name, y], index) => ({
      y,
      name,
      percentage: (y / totalExpenses) * 100,
      legendText: index === explodedIndex ? `\u25CF ${name}` : name,
      showInLegend: true,
      exploded: index === explodedIndex,
    }));

    setData(newData);

    if (explodedIndex === null) {
      setSelectedData({
        label: 'Total Expenses',
        value: totalExpenses,
        percentage: 100,
      });
      setExternalLabel('Total Expenses (100%)');
    }
  }, [
    transactions,
    timePeriod,
    selectedWeeks,
    selectedMonths,
    selectedQuarters,
    selectedYears,
    startDate,
    endDate,
    explodedIndex,
  ]);


  CanvasJS.addColorSet('customColorSet', pieChartColors);

  // Handler for both chart and legend clicks
  const handleDataPointClick = (dataPointIndex) => {
    const isAlreadyExploded = dataPointIndex === explodedIndex;
    setExplodedIndex(isAlreadyExploded ? null : dataPointIndex);

    const clickedDataPoint = data[dataPointIndex];
    setSelectedData(
      isAlreadyExploded
        ? {
            label: 'Total Expenses',
            value: data.reduce((sum, item) => sum + item.y, 0),
            percentage: 100,
          }
        : {
            label: clickedDataPoint.name,
            value: clickedDataPoint.y,
            percentage: clickedDataPoint.percentage,
          }
    );
    setExternalLabel(
      isAlreadyExploded
        ? 'Total Expenses (100%)'
        : `${clickedDataPoint.name} (${clickedDataPoint.percentage.toFixed(1)}%)`
    );
  };

  const options = {
    height: 250,
    animationEnabled: true,
    colorSet: 'customColorSet',
    subtitles: [
      {
        fontFamily: 'arial',
        text: selectedData ? `${formatNumber(selectedData.value)} ` : '',
        verticalAlign: 'center',
        fontSize: width < 768 ? 15 : 24,
        fontWeight: 'bold',
        dockInsidePlotArea: true,
      },
    ],
    legend: {
      fontFamily: 'arial',
      fontWeight: 'lighter',
      horizontalAlign: 'right',
      verticalAlign: 'center',
      fontSize: width < 768 ? 12 : 14,
      maxWidth: width < 768 ? 300 : 500,
      itemWidth: width < 768 ? 140 : 200,
      fontColor: colors.black,
      cursor: 'pointer',
      dockInsidePlotArea: false,
      horizontalDistance: 0,
      verticalDistance: 0,
      itemTextFormatter: function (e) {
        return `${e.dataPoint.name}`;
      },
      item: {
        cursor: 'pointer',
      },
      itemclick: (e) => handleDataPointClick(e.dataPointIndex),
    },
    data: [
      {
        type: 'doughnut',
        radius: width < 768 ? '80%' : '100%',
        innerRadius: width < 768 ? '75%' : '70%',
        yValueFormatString: '$#,##0.00',
        cursor: 'pointer',
        mouseover: function (e) {
          e.dataPoint.cursor = 'pointer';
        },
        dataPoints: data.map((point, index) => ({
          ...point,
          borderWidth: index === explodedIndex ? 3 : 0,
          borderColor: index === explodedIndex ? colors.black : null,
          cursor: 'pointer',
        })),
        explodeOnClick: true,
        click: (e) => handleDataPointClick(e.dataPointIndex),
      },
    ],
    toolTip: {
      fontFamily: 'arial',
      content: '{name}: {y}',
      yValueFormatString: '$#,##0.00',
    },
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '300px' }}>
      <div style={{ textAlign: 'left' }}>
        <div
          style={{
            fontSize: '16px',
            fontWeight: 'bold',
            color: colors.black,
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          {externalLabel}
        </div>
        <div style={{ fontSize: '12px', color: colors.lightGray3 }}>
          From: {formattedStartDate} to {formattedEndDate}
        </div>
      </div>

      <CanvasJSChart
        options={options}
        containerProps={{ width: '100%', height: '100%' }}
      />
    </div>
  );
};

export default PieChart;
