import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import minMax from 'dayjs/plugin/minMax';
import { useCallback, useEffect } from 'react';
import { randomId } from '@mui/x-data-grid-generator';
import { getTransactions } from '../../services/api/transactions-service';
import { updateStore } from '../../../data/store';
import useCategories from './useCategories';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { TRANSACTIONS_QUERY_KEY } from '../../../data/constants';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(minMax);

const fetchTransactionsQuery = async () => {

  updateStore({ isLoadingTransactions: true }); 
  try {
    const response = await getTransactions();
    if (response.status !== 200) {
      throw new Error(`Failed to fetch transactions: Status ${response.status}`);
    }
    return response.data;
  } catch (error) {
    console.error('Error fetching transactions:', error);
  } finally {
    updateStore({ isLoadingTransactions: false }); 
  }
};

const useTransactions = () => {
  const queryClient = useQueryClient();

  useCategories();

  const onSuccess = (responseData) => {
    let uniqueAccounts = new Set();

    const validTransactions = responseData.transactions.filter(
      (transaction) => transaction.is_removed !== 1
    );

    const newRows = validTransactions.map((transaction) => {
      uniqueAccounts.add(transaction.account?.name ?? 'Other');
      return {
        id: transaction.transaction_id ?? randomId(),
        date: dayjs
          .utc(transaction.authorized_date)
          .local()
          .format('YYYY-MM-DD HH:mm:ss'),
        description: transaction.name,
        account: transaction.account?.name ?? 'Other',
        category: transaction?.category?.[0],
        amount: parseFloat(transaction.amount),
        receiptUrl: transaction.receiptUrl,
        isPending: transaction.pending,
      };
    });

    const localMinDateRange = dayjs
      .utc(responseData.minDateRange)
      .local()
      .startOf('day');

    const localMaxDateRange = dayjs
      .utc(responseData.maxDateRange)
      .local()
      .endOf('day');

    updateStore({
      transactions: validTransactions,
      rows: newRows,
      accounts: Array.from(uniqueAccounts).map((name) => ({ name })),
      isLoadingTransactions: false,
      minDateRange: localMinDateRange.toDate(),
      maxDateRange: dayjs.max(localMaxDateRange, dayjs().endOf('day')).toDate(),
    });
  };

  const query = useQuery({
    queryKey: TRANSACTIONS_QUERY_KEY,
    queryFn: fetchTransactionsQuery,
    onSuccess,
    onError: (error) => {
      console.error('Error fetching transactions:', error);
      updateStore({ isLoadingTransactions: false });
    },
    staleTime: Infinity,
    cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

const fetchTransactions = useCallback(
    (props) => {
      const { background = false, invalidate = false} = props || {};
      
      // Only update loading state if not skipped and not a background operation
      if (!background) {
        updateStore({ isLoadingTransactions: true });
      }

      if (invalidate) {
        // For invalidation, we can use a custom option in the query to skip loading
        return queryClient.invalidateQueries({
          queryKey: TRANSACTIONS_QUERY_KEY,
          exact: true,
        });
      }

      const cachedData = queryClient.getQueryData(TRANSACTIONS_QUERY_KEY);
      if (cachedData) {
        onSuccess(cachedData);
      }
    },
    [queryClient]
  );

  useEffect(() => {
    if (query.data) {
      onSuccess(query.data);
    }
  }, [query.data]);

  return {
    ...query,
    fetchTransactions,
  };
};

export default useTransactions;
