import React, { memo } from 'react';
import { SButton } from '../../styles/style.js';
import { randomId } from '@mui/x-data-grid-generator';
import PlaidLink from '../PlaidLink/PlaidLink.js';
import AddIcon from '@mui/icons-material/Add';
import { GridRowModes } from '@mui/x-data-grid';
import {
  Box,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { CustomGridToolbarContainer } from '../../styles/style.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useDashboardContext } from '../../common/contexts/DashboardContext.js';
import TransactionFilter from './transactionFilter.js';
import { store, updateStore } from '../../../data/store.js';
import { useStore } from '@tanstack/react-store';
import ToggleAiSearch from '../AiSearch/ToggleAiSearch.js';
dayjs.extend(utc);

const EditToolbar = ({ setRowModesModel, onFilterChange }) => {
  const {
    setActiveTimeTab,
    setSelectedWeeks,
    setSelectedMonths,
    setSelectedQuarters,
    setSelectedYears,
    setStartDate,
    setEndDate,
    minDateRangeLocal,
    maxDateRangeLocal,
  } = useDashboardContext();

  const { rows, rowEditCount, isLoadingTransactions } = useStore(
    store,
    (state) => ({
      rows: state.rows,
      rowEditCount: state.rowEditCount,
      isLoadingTransactions: state.isLoadingTransactions,
    })
  );

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const setTransactionType = (value) => updateStore({ transactionType: value });

  const handleClick = async () => {
    if (isLoadingTransactions) {
      return;
    }

    updateStore({ isLoadingTransactions: true });

    const hasUnsaved = rows.some((row) => row.isNew);
    if (hasUnsaved) {
      return updateStore({ rows, isLoadingTransactions: false });
    }

    const id = randomId();
    const localDate = new Date();
    const utcDate = dayjs(localDate).utc().format();

    const newRow = {
      id,
      date: utcDate,
      description: '',
      account: 'Other',
      category: '',
      amount: 0,
      isNew: true,
    };

    setActiveTimeTab('all');
    setTransactionType('All');
    setSelectedWeeks([]);
    setSelectedMonths([]);
    setSelectedQuarters([]);
    setSelectedYears([]);
    setStartDate(dayjs(minDateRangeLocal).format('YYYY-MM-DD'));
    setEndDate(dayjs(maxDateRangeLocal).format('YYYY-MM-DD'));
    onFilterChange('All');

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'date' },
    }));

    updateStore({
      rowEditCount: rowEditCount + 1,
      isLoadingTransactions: false,
      rows: [newRow, ...rows],
    });
  };

  const DesktopLayout = () => (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {/* First row: Transactions title and Filters */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          Transactions
        </Typography>
        <TransactionFilter onFilterChange={onFilterChange} />
      </Box>

      {/* Second row: AI Toggle, Link Accounts, Add Transaction */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ flex: 1 }}>
          <ToggleAiSearch />
        </Box>

        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <PlaidLink />
        </Box>

        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Tooltip title="Add Transaction" placement="top" arrow>
            <SButton
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleClick}
              sx={{
                height: '40px',
                whiteSpace: 'nowrap',
                fontSize: '0.875rem',
              }}
            >
              Add Transaction
            </SButton>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );

  const TabletLayout = () => (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {/* First row: Transactions title and Filters */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: 600 }}>
          Transactions
        </Typography>
        <TransactionFilter onFilterChange={onFilterChange} />
      </Box>

      {/* Second row: AI Toggle, Link Accounts, Add Transaction */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ flex: 1 }}>
          <ToggleAiSearch />
        </Box>

        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <PlaidLink />
        </Box>

        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Tooltip title="Add Transaction" placement="top" arrow>
            <SButton
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleClick}
              sx={{
                height: '40px',
                whiteSpace: 'nowrap',
                fontSize: '0.875rem',
              }}
            >
              Add Transaction
            </SButton>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );

  const MobileLayout = () => (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Typography
        variant="h6"
        sx={{
          width: '100%',
          textAlign: 'center',
          fontWeight: 600,
        }}
      >
        Transactions
      </Typography>
      <TransactionFilter onFilterChange={onFilterChange} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          gap: 1,
        }}
      >
        <PlaidLink />
      </Box>
      <Tooltip title="Add Transaction" placement="top" arrow>
        <SButton
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleClick}
          sx={{ height: '40px', width: '100%' }}
        >
          Add Transaction
        </SButton>
      </Tooltip>
    </Box>
  );

  return (
    <CustomGridToolbarContainer
      sx={{
        width: '100%',
        padding: {
          xs: '16px',
          sm: '20px',
          md: '24px',
        },
        marginBottom: '-10px',
      }}
    >
      {isDesktop ? (
        <DesktopLayout />
      ) : isTablet ? (
        <TabletLayout />
      ) : (
        <MobileLayout />
      )}
    </CustomGridToolbarContainer>
  );
};

export default memo(EditToolbar);
