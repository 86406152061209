import React from 'react';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { colors } from '../../../../themes/theme';

const NavigationButton = ({
  direction,
  currentIndex,
  maxIndex,
  onNavigate,
  containerStyle = {},
}) => {
  const isLeft = direction === 'left';
  const isDisabled = isLeft ? currentIndex === 0 : currentIndex === maxIndex;

  const buttonStyle = {
    position: 'absolute',
    left: isLeft ? -20 : 'auto',
    right: !isLeft ? -20 : 'auto',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 2,
    backgroundColor: 'white',
    boxShadow: '0px 2px 8px rgba(0,0,0,0.15)',
    width: 40,
    height: 40,
    opacity: isDisabled ? 0.5 : 1,
    border: 'none',
    borderRadius: '50%',
    cursor: isDisabled ? 'default' : 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 0.2s',
    ...containerStyle,
  };

  return (
    <button
      onClick={onNavigate}
      disabled={isDisabled}
      style={buttonStyle}
      onMouseOver={(e) => {
        if (!isDisabled) {
          e.currentTarget.style.backgroundColor = colors.light;
        }
      }}
      onMouseOut={(e) => {
        e.currentTarget.style.backgroundColor = colors.white;
      }}
    >
      {isLeft ? <ArrowLeftIcon size={24} /> : <ArrowRightIcon size={24} />}
    </button>
  );
};

export default NavigationButton;

