import * as Sentry from '@sentry/react';

const logToSentry = (step, { status = 'info', tags = {}, error = null, extraData = {} } = {}) => {
    if (process.env.NODE_ENV === 'development') {
        return;
    }
    const metadata = {
        step,
        timestamp: new Date().toISOString(),
        device: {
            userAgent: navigator?.userAgent,
            platform: navigator?.platform,
        },
        ...extraData
    };

    Object.entries(tags).forEach(([key, value]) => {
        Sentry.setTag(key, value);
    });

    Sentry.addBreadcrumb({
        category: tags.category || 'user-flow',
        message: `${step}: ${status}`,
        level: status,
        data: metadata,
    });

    if (error) {
        Sentry.captureException(error, {
            tags,
            extra: metadata,
        });
    }
};

export default logToSentry;