import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
  Modal,
  Typography,
  CircularProgress,
  IconButton,
  Paper,
  Fade,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { GridDeleteIcon } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from 'dayjs';
import { useStore } from '@tanstack/react-store';
import styled from '@emotion/styled';
import {
  store,
  createAlert,
  handleCloseReceiptModal,
  updateStore,
} from '../../../data/store';
import uploadService from '../../services/api/upload-service';
import { colors } from '../../../themes/theme';
import { formatCategoryName } from './FormatCategoryName';

const ReceiptModal = ({ open, fetchTransactions }) => {
  const [fileUrl, setFileUrl] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [zoomed, setZoomed] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [expanded, setExpanded] = useState(true); // Initialize as true
  const fileInputRef = useRef(null);
  const dragCounter = useRef(0);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobile = windowWidth < 768;
  const isTablet = windowWidth >= 768 && windowWidth < 1024;

  const { rows } = useStore(store, (state) => ({ rows: state.rows }));
  const { currentReceiptUrl } = useStore(store, (state) => ({
    currentReceiptUrl: state.currentReceiptUrl,
  }));
  const { transactionId } = useStore(store, (state) => ({
    transactionId: state.currentRowId,
  }));

  const {
    transactionDescription,
    transactionDate,
    transactionCategory,
    transactionAccount,
    transactionAmount,
  } = useMemo(() => {
    const currentRow = rows.find((row) => row.id === transactionId);
    return {
      transactionDescription: currentRow?.description,
      transactionDate: currentRow?.date,
      transactionCategory: currentRow?.category,
      transactionAccount: currentRow?.account,
      transactionAmount: currentRow?.amount,
    };
  }, [rows, transactionId]);

  useEffect(() => {
    if (currentReceiptUrl) {
      setFileUrl(currentReceiptUrl);
      const extension = currentReceiptUrl.split('.').pop().toLowerCase();
      setFileType(extension === 'pdf' ? 'pdf' : 'image');
    } else {
      // Keep expanded true on mobile when no file
      if (isMobile) setExpanded(true);
    }
    return () => {
      setFileUrl(null);
      setFileType(null);
      setIsFullscreen(false);
      setExpanded(isMobile); // Reset to true if mobile
    };
  }, [currentReceiptUrl, isMobile]);

  // Rest of the handlers remain the same
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current++;
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setIsDragging(false);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    dragCounter.current = 0;

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleFileUpload(e.dataTransfer.files[0]);
    }
  };

  const handleFileUpload = async (file) => {
    if (file) {
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (!['jpg', 'jpeg', 'png', 'gif', 'pdf'].includes(fileExtension)) {
        createAlert('warning', 'Please upload an image or PDF file.');
        return;
      }

      if (file.size > 5 * 1024 * 1024) {
        createAlert(
          'warning',
          'File size exceeds 5MB. Please choose a smaller file.'
        );
        return;
      }

      setLoading(true);
      try {
        const response = await uploadService.uploadFile(transactionId, file);
        const newFileUrl = response.data.fileUrl;
        setFileUrl(newFileUrl);
        setFileType(fileExtension === 'pdf' ? 'pdf' : 'image');

        updateStore({
          currentReceiptUrl: newFileUrl,
          rows: rows.map((row) =>
            row.id === transactionId ? { ...row, receiptUrl: newFileUrl } : row
          ),
        });

        createAlert('success', 'Receipt uploaded successfully!');
        if (isMobile) setExpanded(true);
        await fetchTransactions({
          background: true,
          invalidate: true,
          skipLoadingState: true,
        });
      } catch (error) {
        console.error('Error uploading file:', error);
        createAlert('error', 'Failed to upload file. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      handleFileUpload(selectedFile);
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleDeleteReceipt = async () => {
    if (fileUrl) {
      try {
        await uploadService.deleteFile(transactionId);
        setFileUrl(null);
        setFileType(null);

        updateStore({
          currentReceiptUrl: null,
          rows: rows.map((row) =>
            row.id === transactionId ? { ...row, receiptUrl: null } : row
          ),
        });

        createAlert('success', 'Receipt deleted successfully!');
        await fetchTransactions({
          background: true,
          invalidate: true,
          skipLoadingState: true,
        });
      } catch (error) {
        console.error('Error deleting file:', error);
        createAlert('error', 'Failed to delete file. Please try again.');
      }
    }
  };

  const handleDownload = async () => {
    if (fileUrl) {
      try {
        const urlParts = fileUrl.split('/');
        const originalFileName = urlParts[urlParts.length - 1];

        const response = await fetch(fileUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);

        const formattedDescription = transactionDescription
          .replace(/[^a-zA-Z0-9]/g, '_')
          .slice(0, 30);
        const formattedDate = dayjs(transactionDate).format('MM-DD-YYYY');
        const fileExtension = originalFileName.split('.').pop();
        const fileName = `receipt_${formattedDescription}_${formattedDate}.${fileExtension}`;

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error('Error downloading file:', error);
        createAlert('error', 'Failed to download file. Please try again.');
      }
    } else {
      createAlert('error', 'Please upload a receipt first.');
    }
  };

  const renderFilePreview = () => {
    if (!fileUrl) return null;

    if (fileType === 'pdf') {
      return (
        <PDFContainer fullscreen={isFullscreen} isMobile={isMobile}>
          <PDFEmbed
            src={`${fileUrl}#toolbar=1&navpanes=0&scrollbar=1&view=FitH`}
            type="application/pdf"
            isMobile={isMobile}
          />
          <FullscreenButton
            onClick={() => setIsFullscreen(!isFullscreen)}
            size="small"
          >
            {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </FullscreenButton>
        </PDFContainer>
      );
    }

    return (
      <ImageContainer>
        <StyledImage
          src={fileUrl}
          alt="Receipt"
          onClick={() => setZoomed(true)}
        />
      </ImageContainer>
    );
  };

  const renderTransactionDetails = () => (
    <>
      <DetailRow>
        <DetailLabel>Date:</DetailLabel>
        <DetailValue>{dayjs(transactionDate).format('MM/DD/YYYY')}</DetailValue>
      </DetailRow>
      <DetailRow>
        <DetailLabel>Category:</DetailLabel>
        <DetailValue>{formatCategoryName(transactionCategory)}</DetailValue>
      </DetailRow>
      <DetailRow>
        <DetailLabel>Account:</DetailLabel>
        <DetailValue>{transactionAccount}</DetailValue>
      </DetailRow>
      <DetailRow>
        <DetailLabel>Amount:</DetailLabel>
        <AmountValue isExpense={parseFloat(transactionAmount) < 0}>
          ${Math.abs(parseFloat(transactionAmount)).toFixed(2)}
        </AmountValue>
      </DetailRow>
    </>
  );

  return (
    <>
      <StyledModal
        open={open}
        onClose={handleCloseReceiptModal}
        closeAfterTransition
      >
        <Fade in={open}>
          <StyledBox
            fullscreen={isFullscreen}
            isMobile={isMobile}
            isTablet={isTablet}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <CloseButton onClick={handleCloseReceiptModal}>
              <CloseIcon />
            </CloseButton>

            <MainContent isMobile={isMobile}>
              {isMobile ? (
                <>
                  <UploadSection
                    isMobile={isMobile}
                    isExpanded={expanded}
                    hasFile={!!fileUrl}
                  >
                    <UploadContainer
                      isDragging={isDragging}
                      hasFile={!!fileUrl}
                      onClick={() => !fileUrl && fileInputRef.current?.click()}
                      isMobile={isMobile}
                      fileType={fileType}
                    >
                      {fileUrl ? (
                        renderFilePreview()
                      ) : (
                        <UploadContent isMobile={isMobile}>
                          {loading ? (
                            <CircularProgress size={48} />
                          ) : (
                            <>
                              <IconWrapper isMobile={isMobile}>
                                <ReceiptLongIcon />
                              </IconWrapper>
                              <Typography variant="h6" gutterBottom>
                                Click or Drag & drop
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                an image or PDF file here
                              </Typography>
                            </>
                          )}
                        </UploadContent>
                      )}
                    </UploadContainer>
                  </UploadSection>

                  <StyledAccordion
                    expanded={expanded}
                    onChange={() => setExpanded(!expanded)}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="transaction-content"
                      id="transaction-header"
                    >
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: 600 }}
                      >
                        {transactionDescription}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div style={{ padding: '8px 0' }}>
                        {renderTransactionDetails()}
                      </div>
                    </AccordionDetails>
                  </StyledAccordion>
                </>
              ) : (
                <>
                  <UploadSection isMobile={isMobile} hasFile={!!fileUrl}>
                    <UploadContainer
                      isDragging={isDragging}
                      hasFile={!!fileUrl}
                      onClick={() => !fileUrl && fileInputRef.current?.click()}
                    >
                      {fileUrl ? (
                        renderFilePreview()
                      ) : (
                        <UploadContent>
                          {loading ? (
                            <CircularProgress size={48} />
                          ) : (
                            <>
                              <IconWrapper>
                                <ReceiptLongIcon />
                              </IconWrapper>
                              <Typography variant="h6" gutterBottom>
                                Click or Drag & drop
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                an image or PDF file here
                              </Typography>
                            </>
                          )}
                        </UploadContent>
                      )}
                    </UploadContainer>
                  </UploadSection>

                  <TransactionSection>
                    <div
                      style={{
                        padding: '16px',
                        borderBottom: `1px solid ${colors.lightGray}`,
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: 600 }}
                      >
                        {transactionDescription}
                      </Typography>
                    </div>
                    <div style={{ padding: '16px' }}>
                      {renderTransactionDetails()}
                    </div>
                  </TransactionSection>
                </>
              )}
            </MainContent>

            <ActionButtons hasFile={!!fileUrl} isMobile={isMobile}>
              <input
                type="file"
                accept="image/*,.pdf"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                ref={fileInputRef}
              />
              <CircularButton
                color={colors.expenses}
                hoverColor={colors.expensesDark}
                onClick={handleDeleteReceipt}
              >
                <GridDeleteIcon />
              </CircularButton>
              <CircularButton
                color={colors.primary}
                hoverColor={colors.primaryDark}
                onClick={handleDownload}
              >
                <DownloadIcon />
              </CircularButton>
            </ActionButtons>
          </StyledBox>
        </Fade>
      </StyledModal>

      {zoomed && fileType === 'image' && (
        <ZoomedOverlay onClick={() => setZoomed(false)}>
          <ZoomedImage src={fileUrl} alt="Zoomed Receipt" />
        </ZoomedOverlay>
      )}
    </>
  );
};

// Styled Components
const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

const StyledBox = styled(Paper)`
  position: relative;
  width: ${(props) => {
    if (props.fullscreen) return '100vw';
    if (props.isMobile) return '100%';
    if (props.isTablet) return '90%';
    return '1000px';
  }};
  height: ${(props) => {
    if (props.fullscreen) return '100vh';
    if (props.isMobile) return '90vh';
    return '600px';
  }};
  max-width: 1200px;
  max-height: ${(props) => (props.fullscreen ? '100vh' : '90vh')};
  background: ${colors.white};
  border-radius: ${(props) => (props.fullscreen ? '0' : '12px')};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
`;

const UploadContainer = styled.div`
  flex: 1;
  border: 2px dashed
    ${(props) => (props.isDragging ? colors.primary : colors.receiptButtonGrey)};
  border-radius: 8px;
  background: ${(props) =>
    props.isDragging ? `${colors.primary}10` : colors.white};
  transition: all 0.3s ease;
  overflow: hidden;
  cursor: ${(props) => !props.hasFile && 'pointer'};
  position: relative;
  display: flex;
  flex-direction: column;

  ${(props) =>
    !props.hasFile &&
    `
    &:hover {
      border-color: ${colors.primary};
      background: ${colors.primary}08;
      transform: scale(0.995);

      ${IconWrapper} {
        transform: translateY(-5px);
        background: ${colors.primaryDark};
      }

      ${Typography} {
        color: ${colors.primary};
      }
    }
  `}
`;

const UploadContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: ${(props) => (props.isMobile ? '16px' : '32px')};
  text-align: center;
`;

const TransactionSection = styled(Paper)`
  width: 320px;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  background: ${colors.white};
`;

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  gap: 8px;

  @media (max-width: 320px) {
    flex-wrap: wrap;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const DetailLabel = styled(Typography)`
  color: ${colors.darkGray2};
  font-size: 0.875rem;

  @media (max-width: 320px) {
    font-size: 0.75rem;
  }
`;

const DetailValue = styled(Typography)`
  color: ${colors.darkGray3};
  font-weight: 500;
  font-size: 0.875rem;

  @media (max-width: 320px) {
    font-size: 0.75rem;
    word-break: break-word;
  }
`;

const AmountValue = styled(Typography)`
  font-weight: 600;
  color: ${(props) => (props.isExpense ? colors.expenses : colors.income)};
  font-size: 0.875rem;

  @media (max-width: 320px) {
    font-size: 0.75rem;
  }
`;

const CircularButton = styled.div`
  width: ${(props) => (props.isMobile ? '40px' : '48px')};
  height: ${(props) => (props.isMobile ? '40px' : '48px')};
  border-radius: 50%;
  background-color: ${(props) =>
    props.disabled ? colors.receiptButtonGrey : props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  transition: all 0.2s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 320px) {
    width: 36px;
    height: 36px;
  }

  svg {
    fill: ${(props) => (props.disabled ? colors.darkGray2 : colors.white)};
    width: ${(props) => (props.isMobile ? '20px' : '24px')};
    height: ${(props) => (props.isMobile ? '20px' : '24px')};

    @media (max-width: 320px) {
      width: 18px;
      height: 18px;
    }
  }

  &:hover {
    background-color: ${(props) =>
      props.disabled ? colors.receiptButtonGrey : props.hoverColor};
    transform: ${(props) => (props.disabled ? 'none' : 'translateY(-1px)')};
  }

  &:active {
    transform: ${(props) => (props.disabled ? 'none' : 'translateY(0)')};
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 10;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  &:hover {
    background: ${colors.lightGray};
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.lightGray};
  padding: ${(props) => (props.isMobile ? '8px' : '16px')};
  overflow: hidden;
  border-radius: 8px;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: zoom-in;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const PDFContainer = styled.div`
  width: 100%;
  height: 100%;
  position: ${(props) => (props.fullscreen ? 'fixed' : 'relative')};
  top: ${(props) => (props.fullscreen ? '0' : 'auto')};
  left: ${(props) => (props.fullscreen ? '0' : 'auto')};
  right: ${(props) => (props.fullscreen ? '0' : 'auto')};
  bottom: ${(props) => (props.fullscreen ? '0' : 'auto')};
  z-index: ${(props) => (props.fullscreen ? '1500' : '1')};
  overflow: hidden;
  background: ${colors.lightGray};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: ${(props) => (props.isMobile ? 'calc(100vh - 200px)' : '100%')};
`;

const PDFEmbed = styled.embed`
  width: 100%;
  height: 100%;
  border: none;
  background: white;
  flex: 1;
`;

const FullscreenButton = styled(IconButton)`
  position: absolute;
  bottom: 16px;
  right: 16px;
  background: rgba(33, 33, 33, 0.9) !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 2;
  width: ${(props) => (props.isMobile ? '36px' : '40px')};
  height: ${(props) => (props.isMobile ? '36px' : '40px')};
  padding: ${(props) => (props.isMobile ? '6px' : '8px')};
  border: 1px solid rgba(255, 255, 255, 0.1);

  @media (max-width: 320px) {
    width: 32px;
    height: 32px;
    padding: 4px;
  }

  svg {
    width: ${(props) => (props.isMobile ? '18px' : '20px')};
    height: ${(props) => (props.isMobile ? '18px' : '20px')};
    fill: white;
    opacity: 0.9;

    @media (max-width: 320px) {
      width: 16px;
      height: 16px;
    }
  }

  &:hover {
    background: rgba(51, 51, 51, 0.95) !important;
    transform: scale(1.05);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.35);

    svg {
      opacity: 1;
    }
  }

  &:active {
    transform: scale(0.98);
  }

  transition: all 0.2s ease;
`;

const ZoomedOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1400;
  cursor: zoom-out;
  backdrop-filter: blur(8px);
  animation: fadeIn 0.2s ease-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const ZoomedImage = styled(StyledImage)`
  max-width: 95vw;
  max-height: 95vh;
  cursor: zoom-out;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    max-width: 98vw;
    max-height: 85vh;
  }

  @media (max-width: 320px) {
    max-width: 100vw;
    max-height: 80vh;
  }
`;

const IconWrapper = styled.div`
  width: ${(props) => (props.isMobile ? '64px' : '80px')};
  height: ${(props) => (props.isMobile ? '64px' : '80px')};
  border-radius: 50%;
  background: ${colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${(props) => (props.isMobile ? '12px' : '16px')};
  transition: all 0.3s ease;

  @media (max-width: 320px) {
    width: 56px;
    height: 56px;
  }

  svg {
    width: ${(props) => (props.isMobile ? '32px' : '40px')};
    height: ${(props) => (props.isMobile ? '32px' : '40px')};
    fill: white;

    @media (max-width: 320px) {
      width: 28px;
      height: 28px;
    }
  }
`;

const ActionButtons = styled.div`
  display: ${(props) => (props.hasFile ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  gap: ${(props) => (props.isMobile ? '8px' : '16px')};
  padding: ${(props) => (props.isMobile ? '12px 8px' : '24px')};
  border-top: 1px solid ${colors.lightGray};
  background: white;
  min-height: ${(props) => (props.isMobile ? '72px' : '96px')};
`;

const UploadSection = styled.div`
  flex: ${(props) => {
    if (!props.isMobile) return '1';
    if (!props.hasFile) return '0.6'; // Increased from 0.4 for better proportions
    return props.isExpanded ? '0.7' : '1'; // Increased from 0.6 for better proportions
  }};
  min-height: ${(props) => {
    if (!props.isMobile) return '0';
    if (!props.hasFile) return '45vh'; // Increased from 30vh
    return props.isExpanded ? '50vh' : '65vh'; // Increased from 40vh/60vh
  }};
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
`;

const StyledAccordion = styled(Accordion)`
  box-shadow: none !important;
  margin: 0 !important;
  border: 1px solid ${colors.lightGray} !important;
  border-radius: 8px !important;
  background-color: ${colors.white} !important;
  flex: ${(props) => (props.expanded ? '0.3' : '0')}; // Reduced from 0.4
  min-height: ${(props) =>
    props.expanded ? '180px' : 'auto'}; // Reduced from 200px
  transition: all 0.3s ease;

  &:before {
    display: none;
  }

  .MuiAccordionSummary-root {
    min-height: 44px; // Reduced from 48px
    padding: 0 8px;

    @media (max-width: 320px) {
      .MuiTypography-subtitle1 {
        font-size: 0.875rem;
      }
    }
  }

  .MuiAccordionDetails-root {
    padding: 0 8px 8px;
    overflow-y: auto;
    max-height: calc(35vh - 44px); // Reduced from 40vh
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  flex: 1;
  overflow: auto;
  padding: ${(props) => (props.isMobile ? '8px' : '16px')};
  gap: ${(props) =>
    props.isMobile ? '4px' : '16px'}; // Reduced from 8px on mobile
  height: calc(100% - 80px);
`;

export default ReceiptModal;
