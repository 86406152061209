import React, {
  createContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import logToSentry from '../../../lib/sentry';


export const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ wsUrl, userId, children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const [syncCompleted, setSyncCompleted] = useState(false);
  const wsRef = useRef(null);
  const reconnectTimeoutRef = useRef(null);
  const pingIntervalRef = useRef(null);

  const connectWebSocket = useCallback(() => {
    if (!wsUrl || !userId) {
      logToSentry('websocket_missing_configuration', {
        status: 'error',
        tags: { category: 'websocket', step: 'validation' },
        extraData: { wsUrl, userId },
      });
      return;
    }

    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
    }

    try {
      const ws = new WebSocket(`${wsUrl}?userId=${userId}`);

      ws.onopen = () => {
        setIsConnected(true);

        if (pingIntervalRef.current) {
          clearInterval(pingIntervalRef.current);
        }
        pingIntervalRef.current = setInterval(() => {
          if (ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify({ type: 'ping' }));
          }
        }, 30000);
      };

      ws.onmessage = (event) => {
        try {
          const message = JSON.parse(event.data);
          switch (message.status) {
            case 'syncing':
              setSyncing(true);
              setSyncCompleted(false);
              logToSentry('websocket_sync_started', {
                tags: { category: 'websocket', step: 'syncing' },
                extraData: { message },
              });
              break;
            case 'complete':
              setSyncing(false);
              setSyncCompleted(true);
              logToSentry('websocket_sync_complete', {
                tags: { category: 'websocket', step: 'complete' },
                extraData: { message },
              });
              break;
            case 'error':
              setSyncing(false);
              setSyncCompleted(false);
              console.error('Sync error:', message.error);
              logToSentry('websocket_sync_error', {
                status: 'error',
                tags: { category: 'websocket', step: 'sync_error' },
                error: new Error('Sync error'),
                extraData: { message },
              });
              break;
            default:
              logToSentry('websocket_unknown_message', {
                tags: { category: 'websocket', step: 'unknown_message' },
                extraData: { message },
              });
          }
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
          logToSentry('websocket_message_parsing_error', {
            status: 'error',
            tags: { category: 'websocket', step: 'message_parsing' },
            error,
            extraData: { rawData: event.data },
          });
        }
      };

      ws.onclose = (event) => {
        setIsConnected(false);

        if (pingIntervalRef.current) {
          clearInterval(pingIntervalRef.current);
          pingIntervalRef.current = null;
        }

        if (event.code !== 1000) {
          logToSentry('websocket_disconnected', {
            status: 'warning',
            tags: { category: 'websocket', step: 'disconnection' },
            extraData: { code: event.code, reason: event.reason },
          });

          if (reconnectTimeoutRef.current) {
            clearTimeout(reconnectTimeoutRef.current);
          }
          reconnectTimeoutRef.current = setTimeout(() => {
            connectWebSocket();
          }, 5000);
        }
      };

      ws.onerror = (error) => {
        logToSentry('websocket_error', {
          status: 'error',
          tags: { category: 'websocket', step: 'error' },
          error,
          extraData: { userId },
        });
      };

      wsRef.current = ws;
    } catch (error) {
      logToSentry('websocket_connection_error', {
        status: 'error',
        tags: { category: 'websocket', step: 'connection' },
        error,
        extraData: { wsUrl, userId },
      });
    }
  }, [wsUrl, userId]);

  // Initial connection
  useEffect(() => {
    connectWebSocket();

    // Cleanup function
    return () => {
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
      if (pingIntervalRef.current) {
        clearInterval(pingIntervalRef.current);
      }
      if (wsRef.current) {
        wsRef.current.close(1000, 'Component unmounting');
        wsRef.current = null;
      }
    };
  }, [connectWebSocket]);

  // Force reconnection function
  const reconnect = useCallback(() => {
    connectWebSocket();
  }, [connectWebSocket]);

  const contextValue = {
    isConnected,
    syncing,
    syncCompleted,
    wsRef,
    reconnect,
    userId,
  };

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
};

