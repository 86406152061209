import ReactGA from 'react-ga4';
import Signup from '../Register/Signup.js';
import Signin from '../Login/Signin';
import React, { useState, useContext } from 'react';
import { Box } from '@mui/material';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../common/contexts/authContext.js';
import { colors } from '../../../themes/theme';

const LandingPage = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const { login, register, buttonLoading } = useContext(AuthContext);
  const navigate = useNavigate();

  const [user, setUser] = useState({
    emailId: '',
    password: '',
  });

  const [registerUser, setRegisterUser] = useState({
    firstName: '',
    lastName: '',
    emailId: '',
    password: '',
    confirmPassword: '',
  });

  const checkConfirmPasswordMatch = (registerUser) =>
    registerUser.password === registerUser.confirmPassword;

  const handleSubmit = async (event) => {
    event.preventDefault();
    const currentPath = window.location.pathname;

    if (currentPath === '/signup') {
      // Register flow
      try {
        if (!checkConfirmPasswordMatch(registerUser)) {
          ReactGA.event({
            category: 'Auth',
            action: 'Registration Error',
            label: 'Password Mismatch',
          });
          throw new Error('Passwords do not match. Please try again.');
        }

        await register(registerUser);
        ReactGA.event({
          category: 'Auth',
          action: 'Registration Success',
        });
      } catch (error) {
        console.error('Error during registration:', error);
        ReactGA.event({
          category: 'Auth',
          action: 'Registration Error',
          label: error.code || 'Unknown Error',
        });
        setErrorMessage(error.message);
      }
    } else {
      // Login flow
      try {
        const loginReq = {
          emailId: registerUser?.emailId || user.emailId,
          password: registerUser?.password || user.password,
        };
        await login(loginReq);
        ReactGA.event({
          category: 'Auth',
          action: 'Login Success',
        });
      } catch (error) {
        ReactGA.event({
          category: 'Auth',
          action: 'Login Error',
          label: error.code || 'Unknown Error',
        });
        console.error('Error during login:', error);
        setErrorMessage(error.message);
      }
    }
  };

    const handleResetPasswordClick = () => {
      ReactGA.event({
        category: 'Auth',
        action: 'Navigate to Reset Password',
      });
      navigate('/login/reset');
    };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: { xs: 1.5, sm: 0 },
        minHeight: '100vh',
        background: colors.PaperBackground,
      }}
    >
      <Routes>
        <Route
          path="/signin"
          element={
            <Signin
              handleSubmit={handleSubmit}
              user={user}
              setUser={setUser}
              handleSignUpClick={() => navigate('/signup')}
              handleResetPasswordClick={handleResetPasswordClick}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              buttonLoading={buttonLoading}
            />
          }
        />
        <Route
          path="/signup"
          element={
            <Signup
              handleSubmit={handleSubmit}
              registerUser={registerUser}
              setRegisterUser={setRegisterUser}
              handleSignInClick={() => navigate('/signin')}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              buttonLoading={buttonLoading}
            />
          }
        />
        <Route path="*" element={<Navigate to="/signup" replace />} />
      </Routes>
    </Box>
  );
};

export default LandingPage;
