import axios from 'axios';
import queryClient from '../lib/queryClient';

// Set the base URL for Axios
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  withCredentials: true,
});

// Axios request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const csrfToken = document.cookie
      .split('; ')
      .find((row) => row.startsWith('x-csrf-token='));
    if (csrfToken) {
      config.headers['x-csrf-token'] = csrfToken.split('=')[1];
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

function deleteAllCookies() {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;';
  }
}

// Axios response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    const csrfToken = response.headers['x-csrf-token'];
    if (csrfToken) {
      document.cookie = `x-csrf-token=${csrfToken};path=/`;
    }
    return response;
  },
  (error) => {
    if (error.response) {
      queryClient.clear();
      if (error.response.status === 401) {
        // Skip redirect if the request is part of the password reset flow
        if (window.location.pathname.includes('/login/reset')) {
          return Promise.reject(error);
        }
        deleteAllCookies();

        // Redirect the user to the login page
        if (window.location.pathname !== '/') {
          window.location.href = '/';
        }
      } else {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error('Response error:', error.response.data);
        console.error('Status code:', error.response.status);
      }
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received:', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error:', error.message);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
