import React, { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import CallIcon from '@mui/icons-material/Call';
import ListItemIcon from '@mui/material/ListItemIcon';
import logoImg from '../../../assets/OnebitMark_White.png';
import onebitImg from '../../../assets/home_page_image.png';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { colors } from '../../../themes/theme';
import { AuthContext } from '../../common/contexts/authContext';
import logToSentry from '../../../lib/sentry';

const Navbar = () => {
  const { currentUser, isLoggedIn, handleLogout } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isDatagridPath = location.pathname === '/dashboard/datagrid';
  const isEditing = isDatagridPath && window.isEditing;

  const handleMenuOpen = (event) => {
    logToSentry('Menu Open', {
      step: 'menu open',
      status: 'success',
      tags: { category: 'user-flow' },
    });
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    logToSentry('Menu Close', {
      step: 'menu close',
      status: 'success',
      tags: { category: 'user-flow' },
    });
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const handleSettings = () => {
    logToSentry('Settings', {
      step: 'settings',
      status: 'success',
      tags: { category: 'user-flow' },
    });
    if (!isEditing) {
      navigate('/settings/profile');
      handleMenuClose();
    }
  };

  const handleContactUs = () => {
    logToSentry('Contact Us', {
      step: 'contact us',
      status: 'success',
      tags: { category: 'user-flow' },
    });
    window.open('https://onebitapp.com/contact-us/', '_blank');
    handleMenuClose();
  };

  const handleLogoClick = () => {
    logToSentry('Logo Click', {
      step: 'logo click',
      status: 'success',
      tags: { category: 'user-flow' },
    });
    if (isLoggedIn && !isEditing) {
      navigate('/dashboard');
    }
  };

  const handleLogoutClick = () => {
    logToSentry('Logout', {
      step: 'logout',
      status: 'success',
      tags: { category: 'user-flow' },
    });
    if (!isEditing) {
      handleLogout();
      handleMenuClose();
    }
  };

  const getAvatarLetters = (email) => {
    if (!email) return '';
    return email.substring(0, 2).toUpperCase();
  };

  const isSettingsPage = currentPath.startsWith('/settings');
  const isProfilePage = currentPath === '/settings/profile';
  const isBankAccountPage = currentPath === '/settings/bank-account';

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: 'transparent' }}>
      <AppBar
        position="static"
        sx={{ backgroundColor: colors.primary, boxShadow: 'none' }}
      >
        <Toolbar>
          <Box
            component="div"
            sx={{
              mr: isSmallScreen ? 4 : 2,
              ml: 0,
              cursor: 'pointer',
            }}
            onClick={handleLogoClick}
          >
            <img
              src={isLoggedIn ? logoImg : onebitImg}
              alt="logo"
              style={{
                height: '40px',
                objectFit: 'contain',
                filter: 'brightness(0) invert(1)',
              }}
            />
          </Box>
          {isSettingsPage && (
            <Typography
              variant={isSmallScreen ? 'h6' : 'h4'}
              sx={{
                ml: isSmallScreen ? -2 : 2,
                flexGrow: 1,
                fontSize: isSmallScreen ? 'x-large' : 'xx-large',
                fontWeight: 'bold',
              }}
            >
              Settings
            </Typography>
          )}
          {isLoggedIn && currentUser && (
            <>
              <Typography variant="h6" sx={{ flexGrow: 1 }}></Typography>
              <Tooltip
                title={
                  isEditing
                    ? 'Please save your changes before accessing settings'
                    : 'Open settings'
                }
              >
                <IconButton
                  onMouseEnter={handleMenuOpen}
                  color="inherit"
                  sx={{
                    backgroundColor: colors.gray,
                    color: colors.black,
                    '&:hover': {
                      backgroundColor: colors.lightGray2,
                    },
                  }}
                >
                  <Avatar
                    sx={{
                      width: isSmallScreen ? 24 : 30,
                      height: isSmallScreen ? 24 : 30,
                      fontSize: isSmallScreen ? 'small' : 'large',
                      fontWeight: 'bold',
                      bgcolor: 'inherit',
                      color: colors.black,
                    }}
                  >
                    {getAvatarLetters(currentUser?.emailId)}
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={isMenuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                  onMouseEnter: () => setIsMenuOpen(true),
                  onMouseLeave: () => setIsMenuOpen(false),
                }}
              >
                <MenuItem
                  onClick={handleSettings}
                  selected={isProfilePage || isBankAccountPage}
                  disabled={isEditing}
                  sx={{
                    backgroundColor:
                      isProfilePage || isBankAccountPage
                        ? `${colors.primary} !important`
                        : 'transparent',
                    color:
                      isProfilePage || isBankAccountPage
                        ? colors.white
                        : colors.black,
                    opacity: isEditing ? 0.5 : 1,
                    '&:hover': {
                      backgroundColor: colors.bgGray,
                      color: colors.black,
                      '&.Mui-selected': {
                        backgroundColor: colors.primary,
                        color: colors.white,
                      },
                    },
                    '&.Mui-disabled': {
                      opacity: 0.5,
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color:
                        isProfilePage || isBankAccountPage
                          ? colors.white
                          : colors.black,
                      opacity: isEditing ? 0.5 : 1,
                    }}
                  >
                    <SettingsIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Settings</Typography>
                </MenuItem>

                <MenuItem
                  onClick={handleContactUs}
                  sx={{
                    '&:hover': {
                      backgroundColor: colors.bgGray,
                      color: colors.black,
                      '& .MuiListItemIcon-root': {
                        color: colors.black,
                      },
                    },
                  }}
                >
                  <ListItemIcon sx={{ color: colors.black }}>
                    <CallIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Contact Us</Typography>
                </MenuItem>

                <MenuItem
                  onClick={handleLogoutClick}
                  disabled={isEditing}
                  sx={{
                    opacity: isEditing ? 0.5 : 1,
                    '&:hover': {
                      backgroundColor: colors.bgGray,
                      color: colors.black,
                      '& .MuiListItemIcon-root': {
                        color: colors.black,
                      },
                      '&.Mui-selected': {
                        backgroundColor: colors.primary,
                        color: colors.white,
                      },
                    },
                    '&.Mui-disabled': {
                      opacity: 0.5,
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: colors.black,
                      opacity: isEditing ? 0.5 : 1,
                    }}
                  >
                    <LogoutIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">Logout</Typography>
                </MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navbar;
