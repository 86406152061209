import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Button,
  Divider,
  CircularProgress,
  Box,
} from '@mui/material';
import { colors } from '../../../../../themes/theme';

const ConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  dialogType = 'item',
  itemName = '',
  confirmationAction = 'unlink',
  verificationText,
  setVerificationText,
}) => {
  const [loading, setLoading] = useState(false);

  const handleConfirmClick = async () => {
    setLoading(true);
    try {
      await onConfirm();
    } catch (error) {
      console.error('Error during confirmation:', error);
    } finally {
      setLoading(false);
    }
  };

  const getConfirmationMessage = () => {
    const itemType = dialogType === 'account' ? 'Account' : 'Bank Item';

    let actionText;
    let details;

    switch (confirmationAction) {
      case 'unlink':
        actionText = 'permanently unlink';
        details =
          dialogType === 'account'
            ? 'all associated transactions, reports, and metrics for this account.'
            : 'all accounts, transactions, reports, and metrics associated with this bank item.';
        break;
      case 'activate':
        actionText = 'reactivate';
        details = 'all associated features and data.';
        break;
      case 'deactivate':
        actionText = 'deactivate';
        details = 'all associated features and data temporarily.';
        break;
      default:
        actionText = 'perform this action';
        details = 'all associated data.';
    }

    return (
      <Box sx={{ textAlign: 'center', }}>
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: '1rem', md: '1.25rem' },
            color: colors.textSecondary,
          }}
        >
          Are you sure you want to {actionText} the {itemType}{' '}
          <Typography
            component="span"
            sx={{
              color: colors.primaryDark,
              fontWeight: 'bold',
              fontSize: { xs: '0.95rem', md: '1.1rem' },
              display: 'inline-block',
              mx: 0.5,
            }}
          >
            "{itemName}"
          </Typography>
          ?
        </Typography>
        <Typography
          sx={{
            color: colors.textSecondary,
            fontSize: { xs: '0.8rem', md: '1rem' },
            lineHeight: 1.6,
            mt: 1,
            textAlign: 'center',
          }}
        >
          This action will{' '}
          {confirmationAction === 'unlink'
            ? 'permanently remove'
            : confirmationAction === 'activate'
              ? 'reactivate'
              : 'temporarily disable'}{' '}
          {details}
        </Typography>
      </Box>
    );
  };


  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: '15px',
          padding: { xs: '0px', md: '20px' },
          width: { xs: '90%', sm: '85%' },
          maxWidth: { xs: '100%', sm: '400px', md: '600px' },
          boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
          backgroundColor: colors.lightGray,
          overflow: 'hidden',
        },
      }}
    >
      <DialogTitle
        sx={{
          justifyContent: 'space-between',
          fontWeight: 'bold',
          fontSize: { xs: '1.25rem', md: '2rem' },
          color: colors.black,
          mb: -2,
          mt: { xs: 0, sm: -2 },
          textAlign: 'center',
        }}
      >
        Confirm{' '}
        {confirmationAction === 'unlink'
          ? 'Unlink'
          : confirmationAction === 'activate'
            ? 'Activation'
            : 'Deactivation'}
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ paddingBottom: 0 }}>
        {getConfirmationMessage()}
        {confirmationAction === 'unlink' && (
          <TextField
            fullWidth
            variant="outlined"
            margin="dense"
            value={verificationText}
            onChange={(e) => setVerificationText(e.target.value)}
            placeholder="Type 'delete' to confirm"
            InputProps={{
              sx: {
                backgroundColor: colors.white,
                borderRadius: '8px',
                mt: { xs: 0, sm: 3 },
                '& input': {
                  padding: '12px',
                  fontSize: { xs: '0.8rem', md: '1rem' },
                },
              },
            }}
          />
        )}
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
          gap: { xs: 1, md: 2 },
          mt: { xs: 1, sm: 3 },
          mb: 2,
        }}
      >
        <Button
          onClick={onClose}
          sx={{
            textTransform: 'none',
            color: colors.primary,
            fontWeight: 'bold',
            padding: { xs: '6px 10px', md: '10px 24px' },
            borderRadius: '20px',
            fontSize: { xs: '0.75rem', md: '1rem' },
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: colors.primaryHover,
              color: colors.white,
            },
          }}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirmClick}
          disabled={
            confirmationAction === 'unlink' &&
            (verificationText.toLowerCase() !== 'delete' || loading)
          }
          sx={{
            textTransform: 'none',
            fontWeight: 'bold',
            padding: { xs: '6px 10px', md: '10px 24px' },
            borderRadius: '20px',
            fontSize: { xs: '0.75rem', md: '1rem' },
            backgroundColor:
              confirmationAction === 'unlink'
                ? verificationText.toLowerCase() === 'delete'
                  ? colors.error
                  : colors.lightError
                : colors.primary,
            color:
              confirmationAction === 'unlink'
                ? verificationText.toLowerCase() === 'delete'
                  ? colors.white
                  : colors.textSecondary
                : colors.white,
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor:
                confirmationAction === 'unlink'
                  ? verificationText.toLowerCase() === 'delete' && !loading
                    ? colors.error
                    : colors.lightError
                  : colors.primaryHover,
            },
          }}
        >
          {loading ? (
            <CircularProgress size={20} color="inherit" />
          ) : confirmationAction === 'unlink' ? (
            'Confirm Unlink'
          ) : confirmationAction === 'activate' ? (
            'Confirm Activation'
          ) : (
            'Confirm Deactivation'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
