import axiosInstance from '../../../api/axiosWrapper';

const uploadFile = async (id, file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    // Add a timestamp to the URL to prevent caching
    const timestamp = Date.now();
    const response = await axiosInstance.post(
      `/uploadReceipt?transactionId=${id}&t=${timestamp}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const deleteFile = async (id) => {
  try {
    const response = await axiosInstance.delete(`/deleteReceipt?transactionId=${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

const uploadService = {
  uploadFile,
  deleteFile,
};

export default uploadService;