import React, { useState, useEffect, useCallback } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Text,
} from 'recharts';
import { Typography, Paper} from '@mui/material';
import _ from 'lodash';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { colors } from '../../../../themes/theme';
import { addCommasToNumber } from '../../Reports/addCommasToNumber';
import NavigationButton from './NavigationButton';

dayjs.extend(isBetween);
dayjs.extend(weekOfYear);

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Paper
        elevation={3}
        style={{ padding: '10px', backgroundColor: colors.white }}
      >
        <Typography variant="subtitle2" component="p">
          {label}
        </Typography>
        <Typography variant="h8" component="p" style={{ fontWeight: 'bold' }}>
          Income: ${addCommasToNumber(payload[0].value)}
        </Typography>
      </Paper>
    );
  }
  return null;
};

const TransactionChart = ({
  transactions,
  timePeriod,
  selectedWeeks,
  selectedMonths,
  selectedQuarters,
  selectedYears,
  startDate,
  endDate,
}) => {
  const [data, setData] = useState([]);
  const [timeGroups, setTimeGroups] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const getDateRangeUnit = (start, end) => {
    const diffDays = dayjs(end).diff(dayjs(start), 'day');
    if (diffDays <= 7) return 'day';
    if (diffDays <= 31) return 'week';
    if (diffDays <= 92) return 'month';
    if (diffDays <= 366) return 'quarter';
    return 'year';
  };

  const getAggregationUnit = useCallback(
    (selectedPeriod) => {
      // Handle custom date range
      if (selectedPeriod === 'all' && startDate && endDate) {
        return getDateRangeUnit(startDate, endDate);
      }

      switch (selectedPeriod) {
        case 'week':
          return 'day';
        case 'month':
          return 'week';
        case 'quarter':
          return 'month';
        case 'year':
          return 'quarter';
        default:
          return 'year';
      }
    },
    [startDate, endDate]
  );

  const formatDate = (date, unit) => {
    switch (unit) {
      case 'day':
        return dayjs(date).format('MMM DD');
      case 'week':
        const startDay = date.startOf('week');
        const monthName = date.format('MMM');
        return `${monthName} ${startDay.date()}`;
      case 'month':
        return dayjs(date).format('MMM YYYY');
      case 'quarter':
        return `Q${Math.floor(dayjs(date).month() / 3) + 1} ${dayjs(date).year()}`;
      case 'year':
        return dayjs(date).format('YYYY');
      default:
        return dayjs(date).format('MM-DD-YY');
    }
  };

  const generateAllTimePoints = useCallback(
    (period, selectedPeriod) => {
      let timePoints = [];

      // Handle custom date range for 'all' period
      if (period === 'all' && startDate && endDate) {
        const unit = getDateRangeUnit(startDate, endDate);
        const start = dayjs(startDate);
        const end = dayjs(endDate);

        switch (unit) {
          case 'day':
            for (
              let d = start;
              d.isBefore(end) || d.isSame(end);
              d = d.add(1, 'day')
            ) {
              timePoints.push(d);
            }
            break;
          case 'week':
            for (
              let d = start.startOf('week');
              d.isBefore(end) || d.isSame(end);
              d = d.add(1, 'week')
            ) {
              timePoints.push(d);
            }
            break;
          case 'month':
            for (
              let d = start.startOf('month');
              d.isBefore(end) || d.isSame(end);
              d = d.add(1, 'month')
            ) {
              timePoints.push(d);
            }
            break;
          case 'quarter':
            for (
              let d = start.startOf('quarter');
              d.isBefore(end) || d.isSame(end);
              d = d.add(1, 'quarter')
            ) {
              timePoints.push(d);
            }
            break;
          case 'year':
            for (
              let d = start.startOf('year');
              d.isBefore(end) || d.isSame(end);
              d = d.add(1, 'year')
            ) {
              timePoints.push(d);
            }
            break;
          default:
            break;
        }
        return timePoints;
      }

      if (period === 'all') {
        return timePoints;
      }

      // Safety check
      if (!selectedPeriod || selectedPeriod.length === 0) {
        return timePoints;
      }

      const currentPeriod = selectedPeriod[currentIndex];
      if (!currentPeriod) {
        return timePoints;
      }

      switch (period) {
        case 'week':
          if (currentPeriod.includes(' - ')) {
            const [start, end] = currentPeriod.split(' - ');
            const year = end.split(', ')[1];
            const startDate = dayjs(`${start}, ${year}`);
            if (startDate.isValid()) {
              for (let i = 0; i < 7; i++) {
                timePoints.push(startDate.add(i, 'day'));
              }
            }
          }
          break;
        case 'month':
          const date = dayjs(currentPeriod);
          if (date.isValid()) {
            const startOfMonth = date.startOf('month');
            for (let i = 0; i < 4; i++) {
              timePoints.push(startOfMonth.add(i * 7, 'day'));
            }
          }
          break;
        case 'quarter':
          if (currentPeriod.includes('Q')) {
            const quarter = parseInt(currentPeriod.charAt(1));
            const year = currentPeriod.split(' ')[1];
            if (!isNaN(quarter) && year) {
              const startMonth = (quarter - 1) * 3;
              for (let i = 0; i < 3; i++) {
                timePoints.push(dayjs(`${year}-${startMonth + i + 1}-01`));
              }
            }
          }
          break;
        case 'year':
          if (!isNaN(currentPeriod)) {
            for (let i = 0; i < 4; i++) {
              timePoints.push(dayjs(`${currentPeriod}-${i * 3 + 1}-01`));
            }
          }
          break;
        default:
          break;
      }

      return timePoints;
    },
    [currentIndex, startDate, endDate]
  );

  useEffect(() => {
    setCurrentIndex(0);
  }, [
    timePeriod,
    selectedWeeks,
    selectedMonths,
    selectedQuarters,
    selectedYears,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (transactions.length > 0) {
      const getSelectedPeriods = (timePeriod) => {
        switch (timePeriod) {
          case 'week':
            return selectedWeeks;
          case 'month':
            return selectedMonths;
          case 'quarter':
            return selectedQuarters;
          default:
            return selectedYears;
        }
      };

      const selectedPeriods = getSelectedPeriods(timePeriod);

      setTimeGroups(selectedPeriods || []);

      const filteredTransactions =
        timePeriod === 'all'
          ? transactions.filter((transaction) => {
              if (startDate && endDate) {
                const transactionDate = dayjs(
                  transaction.authorized_date
                ).local();
                const start = dayjs(startDate).startOf('day');
                const end = dayjs(endDate).endOf('day');
                return transactionDate.isBetween(start, end, null, '[]');
              }
              return true;
            })
          : transactions.filter((transaction) => {
              const transactionDate = dayjs(
                transaction.authorized_date
              ).local();
              const transactionMonthStr = transactionDate.format('MMMM YYYY');
              const transactionQuarterStr = `Q${Math.floor(transactionDate.month() / 3) + 1} ${transactionDate.year()}`;
              const transactionYearStr = transactionDate.format('YYYY');

              switch (timePeriod) {
                case 'week':
                  if (!selectedWeeks.length) return true;
                  return selectedWeeks.some((week) => {
                    const [start, end] = week.split(' - ');
                    const year = end.split(', ')[1];
                    const startDate = dayjs(`${start}, ${year}`).startOf('day');
                    const endDate = dayjs(`${end}, ${year}`).endOf('day');
                    return transactionDate.isBetween(
                      startDate,
                      endDate,
                      null,
                      '[]'
                    );
                  });
                case 'month':
                  if (!selectedMonths.length) return true;
                  return selectedMonths.includes(transactionMonthStr);
                case 'quarter':
                  if (!selectedQuarters.length) return true;
                  return selectedQuarters.includes(transactionQuarterStr);
                case 'year':
                  if (!selectedYears.length) return true;
                  return selectedYears.includes(transactionYearStr);
                default:
                  if (startDate && endDate) {
                    const start = dayjs(startDate).startOf('day');
                    const end = dayjs(endDate).endOf('day');
                    return transactionDate.isBetween(start, end, null, '[]');
                  }
                  return true;
              }
            });

      const positiveTransactions = filteredTransactions.filter(
        (transaction) => parseFloat(transaction.amount) > 0
      );

      const aggregationUnit = getAggregationUnit(timePeriod);
      const allTimePoints = generateAllTimePoints(timePeriod, selectedPeriods);

      // Handle empty time points for 'all' period
      if (allTimePoints.length === 0 && timePeriod === 'all') {
        if (startDate && endDate) {
          const unit = getDateRangeUnit(startDate, endDate);
          const start = dayjs(startDate);
          const end = dayjs(endDate);

          switch (unit) {
            case 'day':
              for (
                let d = start;
                d.isBefore(end) || d.isSame(end);
                d = d.add(1, 'day')
              ) {
                allTimePoints.push(d);
              }
              break;
            case 'week':
              for (
                let d = start.startOf('week');
                d.isBefore(end) || d.isSame(end);
                d = d.add(1, 'week')
              ) {
                allTimePoints.push(d);
              }
              break;
            case 'month':
              for (
                let d = start.startOf('month');
                d.isBefore(end) || d.isSame(end);
                d = d.add(1, 'month')
              ) {
                allTimePoints.push(d);
              }
              break;
            case 'quarter':
              for (
                let d = start.startOf('quarter');
                d.isBefore(end) || d.isSame(end);
                d = d.add(1, 'quarter')
              ) {
                allTimePoints.push(d);
              }
              break;
            default:
              const years = _.uniq(
                filteredTransactions.map((t) =>
                  dayjs(t.authorized_date).format('YYYY')
                )
              ).sort();
              if (years.length >= 1) {
                const startYear = parseInt(years[0]);
                const endYear = parseInt(years[years.length - 1]);
                for (let year = startYear; year <= endYear; year++) {
                  allTimePoints.push(dayjs(`${year}-01-01`));
                }
              }
          }
        } else {
          const years = _.uniq(
            transactions.map((t) => dayjs(t.authorized_date).format('YYYY'))
          ).sort();
          if (years.length >= 1) {
            const startYear = parseInt(years[0]);
            const endYear = parseInt(years[years.length - 1]);
            for (let year = startYear; year <= endYear; year++) {
              allTimePoints.push(dayjs(`${year}-01-01`));
            }
          }
        }
      }

      const baseData = allTimePoints.reduce((acc, timePoint) => {
        const key = formatDate(timePoint, aggregationUnit);
        acc[key] = '0';
        return acc;
      }, {});

      const aggregatedData = _.groupBy(positiveTransactions, (transaction) => {
        const date = dayjs(transaction.authorized_date).local();
        return formatDate(date, aggregationUnit);
      });

      let transformedData = Object.keys(baseData).map((key) => ({
        date: key,
        income: aggregatedData[key]
          ? _.sumBy(aggregatedData[key], (t) => parseFloat(t.amount)).toFixed(2)
          : baseData[key],
      }));

      // Sort data
      transformedData.sort((a, b) => {
        if (aggregationUnit === 'week') {
          return dayjs(a.date).diff(dayjs(b.date));
        } else if (aggregationUnit === 'year') {
          return parseInt(a.date) - parseInt(b.date);
        } else {
          const aDate = dayjs(
            a.date,
            aggregationUnit === 'day' ? 'MMM DD' : 'MMM YYYY'
          );
          const bDate = dayjs(
            b.date,
            aggregationUnit === 'day' ? 'MMM DD' : 'MMM YYYY'
          );
          return aDate.diff(bDate);
        }
      });

      setData(transformedData);
    }
  }, [
    transactions,
    timePeriod,
    selectedWeeks,
    selectedMonths,
    selectedQuarters,
    selectedYears,
    startDate,
    endDate,
    currentIndex,
    getAggregationUnit,
    generateAllTimePoints,
  ]);

  const maxY = Math.max(...data.map((d) => parseFloat(d.income)));
  const hasIncome = data.some((t) => parseFloat(t.income) > 0);

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex < timeGroups.length - 1) {
      setCurrentIndex((prev) => prev + 1);
    }
  };

  if (!hasIncome || data.length === 0) {
    return (
      <div>
        {timePeriod === 'all' && startDate && endDate && (
          <Typography
            variant="subtitle1"
            style={{
              marginBottom: '8px',
              textAlign: 'center',
              color: 'rgba(0, 0, 0, 0.6)',
              fontSize: '0.875rem',
            }}
          >
            From: {dayjs(startDate).format('MMM DD, YYYY')} to{' '}
            {dayjs(endDate).format('MMM DD, YYYY')}
          </Typography>
        )}
        <div style={{ position: 'relative', width: '100%', height: '300px' }}>
          {timeGroups.length > 1 && (
            <>
              <NavigationButton
                direction="left"
                currentIndex={currentIndex}
                maxIndex={timeGroups.length - 1}
                onNavigate={handlePrevious}
              />
              <NavigationButton
                direction="right"
                currentIndex={currentIndex}
                maxIndex={timeGroups.length - 1}
                onNavigate={handleNext}
              />
              <Typography
                variant="subtitle1"
                style={{
                  position: 'absolute',
                  top: -10,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  zIndex: 1,
                  backgroundColor: 'white',
                  padding: '0 12px',
                  borderRadius: '4px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                  fontSize: '0.9rem',
                }}
              >
                {timeGroups[currentIndex]}
              </Typography>
            </>
          )}
          <ResponsiveContainer width="100%" height={300}>
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text style={{ fontSize: '16px', color: colors.lightGray3 }}>
                No data available for this period
              </Text>
            </div>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }

  return (
    <div>
      {timePeriod === 'all' && startDate && endDate && (
        <Typography
          variant="subtitle1"
          style={{
            marginBottom: '8px',
            textAlign: 'center',
            color: 'rgba(0, 0, 0, 0.6)',
            fontSize: '0.875rem',
          }}
        >
          From: {dayjs(startDate).format('MMM DD, YYYY')} to{' '}
          {dayjs(endDate).format('MMM DD, YYYY')}
        </Typography>
      )}
      <div style={{ position: 'relative', width: '100%', height: '300px' }}>
        {timeGroups.length > 1 && (
          <>
            <NavigationButton
              direction="left"
              currentIndex={currentIndex}
              maxIndex={timeGroups.length - 1}
              onNavigate={handlePrevious}
            />
            <NavigationButton
              direction="right"
              currentIndex={currentIndex}
              maxIndex={timeGroups.length - 1}
              onNavigate={handleNext}
            />
            <Typography
              variant="subtitle1"
              style={{
                position: 'absolute',
                top: -10,
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 1,
                backgroundColor: 'white',
                padding: '0 12px',
                borderRadius: '4px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                fontSize: '0.9rem',
              }}
            >
              {timeGroups[currentIndex]}
            </Typography>
          </>
        )}
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: timePeriod === 'all' && startDate && endDate ? 25 : 5,
            }}
          >
            <CartesianGrid strokeDasharray="0" />
            <XAxis
              dataKey="date"
              angle={-45}
              textAnchor="end"
              height={70}
              interval="preserveStartEnd"
              tick={{ fontSize: 12 }}
              padding={{ left: 30, right: 30 }}
            />
            <YAxis
              domain={[0, maxY]}
              tickFormatter={(value) =>
                `${addCommasToNumber(Math.round(value))}`
              }
              tick={{ fontSize: 12 }}
              padding={{ top: 30 }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Area
              type="monotone"
              dataKey="income"
              name="Total Income"
              stroke={colors.primary}
              dot={{
                stroke: colors.primary,
                fill: colors.white,
                fillOpacity: 1,
              }}
              fillOpacity={0.5}
              fill={colors.primary}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default TransactionChart;
