import React, { useState, useCallback } from 'react';
import {
  Box,
  CardContent,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Stack,
  useTheme,
  useMediaQuery,
  IconButton,
  Tooltip,
} from '@mui/material';
import { RestartAlt as RestartAltIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import {
  MobileDateRangePicker,
  MultiInputDateRangeField,
} from '@mui/x-date-pickers-pro';
import dayjs from 'dayjs';
import { colors } from '../../../themes/theme';
import { MetricCard, isDefaultDateRange } from './shared-components';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: 'flex',
  gap: 8,
  width: '90%',
  position: 'relative',
  bottom: 20,
  marginLeft: 'auto',
  marginRight: 'auto',
  zIndex: 1,
  '& .MuiToggleButton-root': {
    flex: 1,
    padding: theme.spacing(0.75),
    color: colors.white,
    backgroundColor: colors.primary,
    borderRadius: '24px',
    fontSize: '0.875rem',
    transition: 'all 0.2s ease',
    '&.Mui-selected': {
      backgroundColor: colors.secondary,
      color: colors.white,
      '&:hover': {
        backgroundColor: colors.secondary,
      },
    },
    '&:hover': {
      backgroundColor: `${colors.primary}dd`,
    },
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1),
    },
  },
}));

const InlineDateRangePicker = ({
  startDate,
  endDate,
  minDateRange,
  maxDateRange,
  onDateChange,
  isCustomRange,
}) => {
  const commonStyles = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '24px',
    },
    '& input': {
      textAlign: 'center',
    },
    '& .MuiInputAdornment-root': {
      marginRight: '8px',
    },
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={1}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
            margin: 0,
            padding: 0,
            color: colors.lightGray3,
          }}
        >
          <Typography variant="body2" sx={{ fontSize: '0.6rem' }}>
            Click below to select a custom date range.
          </Typography>
        </Box>

        <MobileDateRangePicker
          value={[dayjs(startDate), dayjs(endDate)]}
          onChange={(newValue) => {
            if (newValue[0]?.isValid() && newValue[1]?.isValid()) {
              onDateChange(
                newValue[0].format('YYYY-MM-DD'),
                newValue[1].format('YYYY-MM-DD')
              );
            }
          }}
          minDate={dayjs(minDateRange)}
          maxDate={dayjs(maxDateRange)}
          slots={{
            field: MultiInputDateRangeField,
            desktopPaper: ({ children }) => (
              <Box
                sx={{
                  '& .MuiDateRangeCalendar-root': {
                    transform: 'scale(0.9)',
                    transformOrigin: 'top center',
                  },
                }}
              >
                {children}
              </Box>
            ),
          }}
          slotProps={{
            textField: {
              size: 'small',
              sx: {
                ...commonStyles,
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  ...commonStyles['& .MuiOutlinedInput-root'],
                  backgroundColor: 'white',
                  ...(isCustomRange && {
                    '& fieldset': {
                      borderColor: colors.secondary,
                    },
                    '&:hover fieldset': {
                      borderColor: colors.secondary,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: colors.secondary,
                    },
                  }),
                  '& .MuiInputBase-input': {
                    fontSize: '0.813rem',
                    padding: '8px 12px',
                    '@media (max-width: 320px)': {
                      fontSize: '0.75rem',
                      padding: '6px 8px',
                    },
                  },
                },
                '& input': {
                  ...commonStyles['& input'],
                  fontSize: '0.875rem',
                },
              },
            },
            layout: {
              sx: {
                width: '100%',
                '& .MuiDateRangeCalendar-root': {
                  '@media (max-width: 320px)': {
                    transform: 'scale(0.85)',
                    transformOrigin: 'top center',
                  },
                },
              },
            },
          }}
          format="MMM DD, YYYY"
        />
      </Stack>
    </LocalizationProvider>
  );
};

const MobileMetricsDisplay = ({
  income,
  expenses,
  netProfit,
  startDate,
  endDate,
  mobileShortcut,
  onMobileShortcutSelect,
  minDateRange,
  maxDateRange,
  setStartDate,
  setEndDate,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 700px)');
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const isCustomRange = mobileShortcut === 'custom';
  const isDefaultRange = isDefaultDateRange(
    startDate,
    endDate,
    minDateRange,
    maxDateRange
  );

  const handleTimeRangeChange = (_, newRange) => {
    if (newRange !== null) {
      onMobileShortcutSelect(newRange);
    }
  };

  const handleDatePickerOpen = () => setIsDatePickerOpen(true);
  const handleDatePickerClose = () => setIsDatePickerOpen(false);

  const handleDateChange = useCallback(
    (newValue) => {
      if (newValue[0]?.isValid() && newValue[1]?.isValid()) {
        const newStartDate = newValue[0].format('YYYY-MM-DD');
        const newEndDate = newValue[1].format('YYYY-MM-DD');
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        onMobileShortcutSelect('custom');
        handleDatePickerClose();
      }
    },
    [setStartDate, setEndDate, onMobileShortcutSelect]
  );

  const handleReset = useCallback(() => {
    onMobileShortcutSelect('all');
  }, [onMobileShortcutSelect]);

  const timeRangeButtons = [
    { value: '7days', label: '1W' },
    { value: '30days', label: '1M' },
    { value: '90days', label: '3M' },
    { value: '365days', label: '1Y' },
    { value: 'all', label: 'All' },
  ];

  return (
    <>
      <StyledToggleButtonGroup
        value={mobileShortcut}
        exclusive
        onChange={handleTimeRangeChange}
        size={isMobile ? 'small' : 'medium'}
        color="primary"
        sx={{
          width: isTablet ? '85%' : '90%',
          mx: 'auto',
        }}
      >
        {timeRangeButtons.map(({ value, label }) => (
          <ToggleButton key={value} value={value}>
            {label}
          </ToggleButton>
        ))}
      </StyledToggleButtonGroup>

      <Box
        sx={{
          width: {
            xs: '92vw',
            sm: isTablet ? '85vw' : '95vw',
            md: '85vw',
            lg: '80vw',
          },
          height: 'fit-content',
          backgroundColor: 'white',
          mb: 0,
          pb: { xs: 3, sm: 3 },
          borderRadius: 8,
          boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
          position: 'relative',
          zIndex: 0,
          pt: 2,
          mt: -5,
          mx: 'auto',
          overflow: 'hidden',
        }}
      >
        <CardContent sx={{ pb: '16px !important' }}>
          <Stack spacing={2}>
            <Box
              sx={{
                marginTop: '0 !important',
                display: 'flex',
                justifyContent: 'space-between',
                gap: 1,
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <InlineDateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  minDateRange={minDateRange}
                  maxDateRange={maxDateRange}
                  onDateChange={handleDateChange}
                  isCustomRange={isCustomRange}
                  isOpen={isDatePickerOpen}
                  onOpen={handleDatePickerOpen}
                  onClose={handleDatePickerClose}
                />
              </Box>
              <Box sx={{ mt: 1 }}>
                <Tooltip title="Reset date range" placement="top">
                  <span>
                    <IconButton
                      onClick={handleReset}
                      disabled={isDefaultRange}
                      size="small"
                      sx={{
                        top: '15px',
                        backgroundColor: colors.secondary,
                        color: colors.white,
                        transition: 'all 0.2s ease',
                        '&:hover': {
                          backgroundColor: colors.hoverBg,
                          transform: 'scale(1.05)',
                        },
                        '&.Mui-disabled': {
                          backgroundColor:
                            theme.palette.action.disabledBackground,
                          color: theme.palette.action.disabled,
                        },
                      }}
                    >
                      <RestartAltIcon fontSize="small" />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                gap: 2,
                flexDirection: {
                  xs: 'column',
                  sm: isTablet ? 'column' : 'row',
                },
                justifyContent: 'flex-start',
                alignItems: isTablet ? 'center' : 'stretch',
                px: isTablet ? 2 : 0,
              }}
            >
              <MetricCard
                label="INCOME"
                amount={parseFloat(income.replace(/[^0-9.-]+/g, ''))}
                type="positive"
                isMobile={isMobile}
                isTablet={isTablet}
              />
              <MetricCard
                label="EXPENSES"
                amount={parseFloat(expenses.replace(/[^0-9.-]+/g, ''))}
                type="negative"
                isMobile={isMobile}
                isTablet={isTablet}
              />
              <MetricCard
                label="NET POSITION"
                amount={parseFloat(netProfit.replace(/[^0-9.-]+/g, ''))}
                type={
                  parseFloat(netProfit.replace(/[^0-9.-]+/g, '')) >= 0
                    ? 'positive'
                    : 'negative'
                }
                isMobile={isMobile}
                isTablet={isTablet}
              />
            </Box>
          </Stack>
        </CardContent>
      </Box>
    </>
  );
};

export default MobileMetricsDisplay;
