export const formatCategoryName = (categoryName) => {
  if (!categoryName) return '';

  // Split by underscores and convert to lowercase
  return categoryName
    .split('_')
    .map((word) => {
      // Handle empty strings
      if (!word) return '';

      // Convert word to lowercase first
      const lowercaseWord = word.toLowerCase();

      // Capitalize first letter
      return lowercaseWord.charAt(0).toUpperCase() + lowercaseWord.slice(1);
    })
    .join(' ');
};

// Example usage:
// formatCategoryName('FOOD_AND_DINING') => "Food And Dining"
// formatCategoryName('HOME_UTILITIES') => "Home Utilities"
// formatCategoryName('ENTERTAINMENT') => "Entertainment"
// formatCategoryName('') => ""
// formatCategoryName(null) => ""
// formatCategoryName('MULTIPLE__UNDERSCORES') => "Multiple Underscores"
// formatCategoryName('already_Formatted_Category') => "Already Formatted Category"

// If you need to format multiple categories at once
export const formatCategories = (categories) => {
  if (!Array.isArray(categories)) return [];

  return categories.map((category) => formatCategoryName(category));
};
