import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker';
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro/MobileDateRangePicker';
import {
  Tooltip,
  useMediaQuery,
  useTheme,
  InputAdornment,
  Box,
  Typography,
  Button,
} from '@mui/material';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import { colors } from '../../../themes/theme';
import { ResetButton } from './styled';
import { addCommasToNumber } from './addCommasToNumber';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const ReportFilter = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  income,
  costOfGoodsSold,
  operatingExpenses,
  netProfit,
  maxDateRangeLocal,
  handleReset,
  isResetDisabled,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallScreen = useMediaQuery('(max-width:375px)');
  const isVerySmallScreen = useMediaQuery('(max-width:374px)');

  const handleDateChange = (newDateRange) => {
    if (newDateRange[0] && newDateRange[1]) {
      setStartDate(newDateRange[0].toDate());
      setEndDate(newDateRange[1].toDate());
    }
  };

  const commonInputProps = {
    endAdornment: !isSmallScreen ? (
      <InputAdornment position="end">
        <CalendarTodayIcon />
      </InputAdornment>
    ) : null,
  };

  const commonStyles = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '24px',
      ...(isVerySmallScreen && {
        width: '110%',
        marginLeft: '-5%',
      }),
    },
    '& input': {
      textAlign: 'center',
    },
    '& .MuiInputAdornment-root': {
      marginRight: '8px',
    },
  };

  // Helper function to check if a shortcut should be disabled
  const isShortcutDisabled = (shortcutRange, maxDate) => {
    const [startDate, endDate] = shortcutRange;

    // If no max date set, enable all shortcuts
    if (!maxDate) return false;

    const maxDayjs = dayjs.isDayjs(maxDate) ? maxDate : dayjs(maxDate);

    // Disable if either:
    // 1. The end date exceeds maxDate
    // 2. The date range is invalid
    return endDate.isAfter(maxDayjs) || endDate.isBefore(startDate);
  };

  const getShortcutGroups = (maxDate) => [
    [
      {
        label: 'This Year',
        getValue: () => {
          const range = [dayjs().startOf('year'), dayjs()];
          return range;
        },
        isDisabled: (maxDate) =>
          isShortcutDisabled([dayjs().startOf('year'), dayjs()], maxDate),
      },
      {
        label: 'Last Year',
        getValue: () => {
          const lastYear = dayjs().subtract(1, 'year');
          const range = [lastYear.startOf('year'), lastYear.endOf('year')];
          return range;
        },
        isDisabled: (maxDate) =>
          isShortcutDisabled(
            [
              dayjs().subtract(1, 'year').startOf('year'),
              dayjs().subtract(1, 'year').endOf('year'),
            ],
            maxDate
          ),
      },
    ],
    [
      {
        label: 'Last 30 Days',
        getValue: () => {
          const range = [dayjs().subtract(29, 'day'), dayjs()];
          return range;
        },
        isDisabled: (maxDate) =>
          isShortcutDisabled([dayjs().subtract(29, 'day'), dayjs()], maxDate),
      },
      {
        label: 'Last 90 Days',
        getValue: () => {
          const range = [dayjs().subtract(89, 'day'), dayjs()];
          return range;
        },
        isDisabled: (maxDate) =>
          isShortcutDisabled([dayjs().subtract(89, 'day'), dayjs()], maxDate),
      },
    ],
    [
      {
        label: 'Last 6 Months',
        getValue: () => {
          const range = [dayjs().subtract(6, 'month'), dayjs()];
          return range;
        },
        isDisabled: (maxDate) =>
          isShortcutDisabled([dayjs().subtract(6, 'month'), dayjs()], maxDate),
      },
      {
        label: 'Last 12 Months',
        getValue: () => {
          const range = [dayjs().subtract(12, 'month'), dayjs()];
          return range;
        },
        isDisabled: (maxDate) =>
          isShortcutDisabled([dayjs().subtract(12, 'month'), dayjs()], maxDate),
      },
    ],
  ];

  const isShortcutSelected = (shortcut, currentStartDate, currentEndDate) => {
    const shortcutRange = shortcut.getValue();
    return (
      dayjs(currentStartDate)
        .startOf('day')
        .isSame(shortcutRange[0].startOf('day')) &&
      dayjs(currentEndDate)
        .startOf('day')
        .isSame(shortcutRange[1].startOf('day'))
    );
  };

  const DateSelector = () => {
    const shortcuts = getShortcutGroups(dayjs(maxDateRangeLocal))
      .flat()
      .map((shortcut) => ({
        label: shortcut.label,
        getValue: shortcut.getValue,
        isDisabled: shortcut.isDisabled(dayjs(maxDateRangeLocal)),
      }));

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {isMobile ? (
          <MobileDateRangePicker
            value={[dayjs(startDate), dayjs(endDate)]}
            onChange={handleDateChange}
            minDate={null}
            maxDate={dayjs(maxDateRangeLocal)}
            slotProps={{
              textField: {
                size: 'small',
                fullWidth: true,
                sx: {
                  ...commonStyles,
                  '& input': {
                    ...commonStyles['& input'],
                    fontSize: '0.75rem',
                  },
                },
              },
              field: {
                format: 'MMM DD, YYYY',
              },
              dialog: {
                sx: {
                  '& .MuiDialog-paper': {
                    width: '100%',
                    maxWidth: '360px',
                    margin: '16px',
                    maxHeight: 'calc(100% - 32px)',
                    display: 'flex',
                    flexDirection: 'column',
                  },
                  '& .MuiDialogContent-root': {
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                  },
                  '& .MuiDateRangeCalendar-root': {
                    width: '100%',
                    margin: '0 auto',
                    minHeight: '320px',
                  },
                  '& .MuiPickersCalendarHeader-root': {
                    paddingLeft: 2,
                    paddingRight: 2,
                    marginTop: 1,
                  },
                  '& .MuiDayPicker-header': {
                    justifyContent: 'space-around',
                  },
                  '& .MuiPickersDay-root': {
                    width: '36px',
                    height: '36px',
                    fontSize: '0.875rem',
                    '@media (max-width: 320px)': {
                      width: '32px',
                      height: '32px',
                      fontSize: '0.8rem',
                    },
                  },
                },
              },
              layout: {
                sx: {
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                },
              },
            }}
            slots={{
              toolbar: () => (
                <Box
                  sx={{
                    p: 2,
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    bgcolor: 'background.paper',
                  }}
                >
                  <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
                    Select date range
                  </Typography>
                  {getShortcutGroups(dayjs(maxDateRangeLocal)).map(
                    (group, groupIndex) => (
                      <Box
                        key={groupIndex}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          gap: 1,
                          mb:
                            groupIndex <
                            getShortcutGroups(dayjs(maxDateRangeLocal)).length -
                              1
                              ? 1
                              : 0,
                          width: '100%',
                        }}
                      >
                        {group.map((shortcut, index) => (
                          <Button
                            key={index}
                            variant="text"
                            size="small"
                            onClick={() =>
                              handleDateChange(shortcut.getValue())
                            }
                            disabled={shortcut.isDisabled(
                              dayjs(maxDateRangeLocal)
                            )}
                            sx={{
                              borderRadius: '16px',
                              textTransform: 'none',
                              fontSize: isVerySmallScreen
                                ? '0.75rem'
                                : '0.875rem',
                              flex: '1',
                              maxWidth: '45%',
                              whiteSpace: 'nowrap',
                              backgroundColor: isShortcutSelected(
                                shortcut,
                                startDate,
                                endDate
                              )
                                ? 'primary.main'
                                : 'rgba(0, 0, 0, 0.08)',
                              color: isShortcutSelected(
                                shortcut,
                                startDate,
                                endDate
                              )
                                ? 'white'
                                : 'rgba(0, 0, 0, 0.87)',
                              '&:hover': {
                                backgroundColor: isShortcutSelected(
                                  shortcut,
                                  startDate,
                                  endDate
                                )
                                  ? 'primary.dark'
                                  : 'rgba(0, 0, 0, 0.12)',
                              },
                              '&:disabled': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                color: 'rgba(0, 0, 0, 0.38)',
                              },
                              border: 'none',
                              padding: '6px 16px',
                            }}
                          >
                            {shortcut.label}
                          </Button>
                        ))}
                      </Box>
                    )
                  )}
                </Box>
              ),
            }}
          />
        ) : (
          <DesktopDateRangePicker
            value={[dayjs(startDate), dayjs(endDate)]}
            onChange={handleDateChange}
            minDate={null}
            maxDate={dayjs(maxDateRangeLocal)}
            calendars={2}
            slotProps={{
              textField: {
                size: 'small',
                InputProps: commonInputProps,
                sx: {
                  ...commonStyles,
                  width: '100%',
                  '& input': {
                    ...commonStyles['& input'],
                    fontSize: '0.875rem',
                  },
                },
              },
              popper: {
                sx: {
                  '& .MuiPaper-root': {
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 0,
                  },
                  '& .MuiPickersLayout-root': {
                    display: 'flex',
                    flexDirection: 'row',
                  },
                  '& .MuiPickersLayout-shortcuts': {
                    order: -1,
                  },
                },
              },
              shortcuts: {
                items: shortcuts,
                sx: {
                  minWidth: '180px',
                  borderRight: '1px solid',
                  borderColor: 'divider',
                  padding: 0,
                  '& .MuiButtonBase-root': {
                    width: '100%',
                  },
                },
              },
            }}
            slots={{
              shortcuts: (props) => {
                const { items = [], onChange } = props;
                return (
                  <Box
                    sx={{
                      p: 1.5,
                      minWidth: '180px',
                    }}
                  >
                    <Typography variant="h6" sx={{ mb: 2, pl: 1 }}>
                      Select date range
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                      }}
                    >
                      {items.map((item, index) => (
                        <Button
                          key={index}
                          onClick={() => onChange(item.getValue())}
                          disabled={item.isDisabled}
                          sx={{
                            backgroundColor: isShortcutSelected(
                              item,
                              startDate,
                              endDate
                            )
                              ? 'primary.main'
                              : 'rgba(0, 0, 0, 0.08)',
                            color: isShortcutSelected(item, startDate, endDate)
                              ? 'white'
                              : 'rgba(0, 0, 0, 0.87)',
                            '&:hover': {
                              backgroundColor: isShortcutSelected(
                                item,
                                startDate,
                                endDate
                              )
                                ? 'primary.dark'
                                : 'rgba(0, 0, 0, 0.12)',
                            },
                            '&:disabled': {
                              backgroundColor: 'rgba(0, 0, 0, 0.04)',
                              color: 'rgba(0, 0, 0, 0.38)',
                            },
                            textTransform: 'none',
                            borderRadius: '20px',
                            padding: '8px 16px',
                            whiteSpace: 'nowrap',
                            width: '100%',
                          }}
                        >
                          {item.label}
                        </Button>
                      ))}
                    </Box>
                  </Box>
                );
              },
            }}
            format="MMM DD, YYYY"
          />
        )}
      </LocalizationProvider>
    );
  };

  const MobileView = () => (
    <Box>
      <Typography
        variant="subtitle1"
        fontWeight="bold"
        gutterBottom
        sx={{ marginBottom: 2 }}
      >
        Date Range
      </Typography>
      <Box display="flex" flexDirection="column" gap={2}>
        <DateSelector />
        <Tooltip title="Reset Date Range" placement="right" arrow>
          <ResetButton
            disabled={isResetDisabled}
            variant="contained"
            color="primary"
            onClick={handleReset}
            fullWidth
            sx={{
              height: '32px',
              borderRadius: '24px',
            }}
          >
            Reset
          </ResetButton>
        </Tooltip>
      </Box>
      <Box mt={2}>
        {[
          { label: 'Income', value: income },
          {
            label: 'Cost of Goods Sold',
            value: costOfGoodsSold,
            isSubtraction: true,
          },
          {
            label: 'Operating Expenses',
            value: operatingExpenses,
            isSubtraction: true,
          },
          {
            label: netProfit < 0 ? 'Net Loss' : 'Net Profit',
            value: netProfit,
            isTotal: true,
          },
        ].map((item, index) => (
          <Box
            key={index}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={1}
          >
            <Typography
              variant="body1"
              fontWeight={item.isTotal ? 'bold' : 'normal'}
              sx={isVerySmallScreen ? { fontSize: '0.9rem' } : {}}
            >
              {item.label}
            </Typography>
            <Typography
              variant="body1"
              fontWeight={item.isTotal ? 'bold' : 'normal'}
              color={
                item.isTotal
                  ? netProfit < 0
                    ? 'error.main'
                    : 'success.main'
                  : 'text.primary'
              }
              sx={isVerySmallScreen ? { fontSize: '0.9rem' } : {}}
            >
              {item.isSubtraction && '-'}$
              {addCommasToNumber(Math.abs(item.value?.toFixed?.(2)))}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );

  return (
    <Container>
      {isMobile ? (
        <MobileView />
      ) : (
        <>
          <Row>
            <Label htmlFor="date-range">Date Range</Label>
            <DateRangeWrapper>
              <DatePickerContainer>
                <DateSelector />
              </DatePickerContainer>
              <Tooltip title="Reset Date Range" placement="right" arrow>
                <ResetButton
                  disabled={isResetDisabled}
                  variant="contained"
                  color="primary"
                  onClick={handleReset}
                  sx={{
                    borderRadius: '24px',
                    marginLeft: 'auto',
                  }}
                >
                  Reset
                </ResetButton>
              </Tooltip>
            </DateRangeWrapper>
          </Row>
          <CalculationRow>
            <CalculationItem>
              <span>${addCommasToNumber(Math.abs(income?.toFixed?.(2)))}</span>
              <small>Income</small>
            </CalculationItem>
            <span className="operator">-</span>
            <CalculationItem>
              <span>
                ${addCommasToNumber(Math.abs(costOfGoodsSold?.toFixed?.(2)))}
              </span>
              <small>Cost of Goods Sold</small>
            </CalculationItem>
            <span className="operator">-</span>
            <CalculationItem>
              <span>
                ${addCommasToNumber(Math.abs(operatingExpenses?.toFixed?.(2)))}
              </span>
              <small>Operating Expenses</small>
            </CalculationItem>
            <span className="operator">=</span>
            <CalculationItem color={netProfit < 0 ? 'red' : 'green'}>
              <span>
                {netProfit < 0 ? '-$' : '$'}
                {addCommasToNumber(Math.abs(netProfit)?.toFixed?.(2))}
              </span>
              <small>Net {netProfit < 0 ? 'Loss' : 'Profit'}</small>
            </CalculationItem>
          </CalculationRow>
        </>
      )}
    </Container>
  );
};

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  border-radius: 5px;
  width: 100%;
  padding: 20px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const Label = styled.label`
  font-weight: bold;
  width: 100px;
  white-space: nowrap;
`;

const DateRangeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const DatePickerContainer = styled.div`
  flex: 1;
`;

const CalculationRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 1.2em;
  border: 1px solid ${colors.gray};
  padding: 16px 24px;
  border-radius: 20px;
  width: fit-content;
  max-width: 95%;
  margin: 0 auto;
  overflow-x: auto;

  > span.operator {
    font-size: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 40px;
    margin: 0 4px;
    position: relative;
    top: -8px;
    flex-shrink: 0;
  }

  @media (max-width: 1024px) {
    padding: 12px 16px;
    gap: 4px;
  }
`;

const CalculationItem = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.color || 'black'};
  font-weight: bold;
  min-width: 120px;
  max-width: 180px;
  padding: 0 8px;
  text-align: center;
  position: relative;
  height: 60px;

  span {
    width: 100%;
    font-size: 1.3em;
    line-height: 1.2;
    white-space: nowrap;
    text-align: center;
    display: block;
  }

  small {
    width: 100%;
    font-size: 0.4em;
    color: ${colors.primary};
    text-align: center;
    font-weight: normal;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    white-space: nowrap;
  }

  @media (min-width: 768px) {
    font-size: 0.9em !important;
    min-width: 130px;
    max-width: 180px;
    margin-bottom: -3px;
  }
  @media (min-width: 1024px) {
    font-size: 1.2em !important;
    min-width: 150px;
    max-width: 200px;
  }
  @media (min-width: 1440px) {
    font-size: 1.4em !important;
    min-width: 170px;
    max-width: 220px;
  }
`;

export default ReportFilter;
