import React, { useState, useContext, useRef } from 'react';
import TextField from '@mui/material/TextField';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { SButton } from './style';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthContext } from '../../common/contexts/authContext';
import logoimg from '../../../assets/OnebitMark_White.png';
import { colors } from '../../../themes/theme';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
      sx={{
        marginTop: 0.5,
        fontSize: '0.875rem',
        color: colors.text,
        '& a': {
          color: 'inherit',
          textDecoration: 'none',
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      }}
    >
      {'Copyright © '}
      <Link color="inherit" href="#" underline="hover">
        ONEBIT
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const SignIn = ({
  user,
  setUser,
  handleSignUpClick,
  handleResetPasswordClick,
  errorMessage,
  setErrorMessage,
  buttonLoading,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({ email: '', password: '' });
  const sButtonRef = useRef(null);
  const { login } = useContext(AuthContext);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (sButtonRef.current) {
        sButtonRef.current.click();
      }
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let formErrors = {};

    if (!user.emailId) {
      formErrors.email = 'Email is required';
    } else if (!validateEmail(user.emailId)) {
      formErrors.email = 'Invalid email address';
    }

    if (!user.password) {
      formErrors.password = 'Password is required';
    } else if (user.password.length < 8) {
      formErrors.password = 'Password must be at least 8 characters';
    }

    if (Object.keys(formErrors).length === 0) {
      try {
        await login(user);
      } catch (error) {
        setErrorMessage(error.message || 'Failed to log in. Please try again.');
      }
    } else {
      setErrors(formErrors);
    }
  };

  const handleError = () => {
    if (errorMessage) setErrorMessage('');
    setErrors({ email: '', password: '' });
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100%',
        padding: { xs: 2, sm: 4 },
        boxShadow: '0 10px 30px rgba(0, 0, 0, 0.3)',
        borderRadius: 3,
        backgroundImage:
          'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
      }}
    >
      {/* Logo Container */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 3,
          mt: { xs: 0, sm: -2 },
          backgroundColor: colors.primary,
          boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
          borderRadius: 2,
          padding: 1,
          width: 'fit-content',
        }}
      >
        <img
          src={logoimg}
          alt="logo"
          style={{
            maxWidth: '80px',
            height: '35px',
            display: 'block',
          }}
        />
      </Box>

      {/* Content Container */}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        {/* Header */}
        <Box sx={{ textAlign: 'center' }}>
          <Typography
            component="h1"
            variant="h4"
            sx={{
              fontWeight: 'bold',
              background: colors.signInText,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              marginBottom: 0.5,
              textAlign: 'center',
              fontSize: { xs: '1.8rem', sm: '2rem' },
            }}
          >
            Welcome Back!
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: colors.text,
              fontSize: { xs: '0.9rem', sm: '0.95rem' },
            }}
          >
            Don't have an account yet?{' '}
            <Link
              component={RouterLink}
              to="/signup"

              style={{ color: 'primary.main', fontWeight: 600 }}
            >
              Sign up
            </Link>
          </Typography>
        </Box>

        {/* Form */}
        <Box
          component="form"
          noValidate
          onSubmit={handleFormSubmit}
          onKeyDown={handleKeyDown}
          sx={{ width: '100%' }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                component="label"
                htmlFor="email"
                marginLeft={1}
                sx={{
                  display: 'block',
                  mb: 1,
                  fontSize: '0.95rem',
                  fontWeight: 500,
                  color: 'text.primary',
                }}
              >
                Email
              </Typography>
              <TextField
                defaultValue={user.emailId}
                required
                fullWidth
                id="email"
                placeholder="Enter your email"
                name="email"
                autoComplete="email"
                error={Boolean(errors.email)}
                helperText={errors.email}
                onChange={(event) => {
                  setUser({ ...user, emailId: event.target.value });
                  handleError();
                }}
                size="small"
                variant="outlined"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    backgroundColor: colors.PasswordBackground,
                    transition: 'all 0.2s',
                    '&:hover': {
                      backgroundColor: colors.PasswordHover,
                    },
                  },
                  '&.Mui-focused': {
                    backgroundColor: colors.white,
                    boxShadow: '0 0 0 2px rgba(21, 101, 192, 0.1)',
                  },
                  '& .MuiInputLabel-root': {
                    fontSize: '0.9rem',
                  },
                  '& .MuiInputBase-input': {
                    fontSize: '0.9rem',
                    padding: '8px 10px',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                component="label"
                htmlFor="password"
                sx={{
                  display: 'block',
                  mb: 1,
                  fontSize: '0.95rem',
                  fontWeight: 500,
                  color: 'text.primary',
                }}
              >
                Password
              </Typography>
              <TextField
                required
                fullWidth
                id="password"
                placeholder="Enter your password"
                type={showPassword ? 'text' : 'password'}
                name="password"
                autoComplete="current-password"
                error={Boolean(errors.password)}
                helperText={errors.password}
                onChange={(event) => {
                  setUser({ ...user, password: event.target.value });
                  handleError();
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        sx={{ color: 'text.secondary' }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                size="small"
                variant="outlined"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    backgroundColor: colors.PasswordBackground,
                    transition: 'all 0.2s',
                    '&:hover': {
                      backgroundColor: colors.PasswordHover,
                    },
                  },
                  '&.Mui-focused': {
                    backgroundColor: colors.white,
                    boxShadow: '0 0 0 2px rgba(21, 101, 192, 0.1)',
                  },
                  '& .MuiInputLabel-root': {
                    fontSize: '0.9rem',
                  },
                  '& .MuiInputBase-input': {
                    fontSize: '0.9rem',
                    padding: '8px 10px',
                  },
                }}
              />
            </Grid>
          </Grid>

          {errorMessage && (
            <Alert
              severity="error"
              sx={{
                mt: 2,
                borderRadius: 1.5,
                fontSize: '0.95rem',
                '& .MuiAlert-icon': {
                  fontSize: '1.25rem',
                },
              }}
            >
              {errorMessage}
            </Alert>
          )}

          <SButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              marginTop: 4,
              marginBottom: 2,
              padding: '8px',
              fontSize: '1rem',
              borderRadius: 2,
              fontWeight: 600,
              background: 'linear-gradient(45deg, #1565C0 30%, #0D47A1 90%)',
              transition: 'all 0.3s ease',
              textTransform: 'none',
              letterSpacing: '0.5px',
              '&:hover': {
                background: 'linear-gradient(45deg, #1976D2 30%, #1565C0 90%)',
                transform: 'translateY(-2px)',
              },
              '&:active': {
                transform: 'translateY(0)',
              },
              '&:disabled': {
                background: 'linear-gradient(45deg, #90CAF9 30%, #64B5F6 90%)',
                boxShadow: 'none',
              },
            }}
            disabled={buttonLoading}
            ref={sButtonRef}
            startIcon={
              buttonLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : null
            }
          >
            {buttonLoading ? '' : 'Sign In'}
          </SButton>

          <Link
            component="button"
            variant="body2"
            onClick={handleResetPasswordClick}
            sx={{
              display: 'block',
              width: '100%',
              mt: 2,
              textAlign: 'center',
              color: 'primary.main',
              fontSize: '0.95rem',
              fontWeight: 500,
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            Forgot your password?
          </Link>
        </Box>

        <Copyright />
      </Box>
    </Container>
  );
};

export default SignIn;
