import React, { useState, useContext } from 'react';
import TextField from '@mui/material/TextField';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { SButton } from '../Login/style';
import Alert from '@mui/material/Alert';
import SubmitPasswordForm from '../../common/SubmitPasswordForm';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthContext } from '../../common/contexts/authContext';
import logoimg from '../../../assets/OnebitMark_White.png';
import { colors } from '../../../themes/theme';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
      sx={{
        marginTop: 2,
        marginBottom: 3,
        fontSize: '0.8rem',
        color: colors.text,
      }}
    >
      {'By signing up, you are indicating that you have read and agree to the '}
      <Link href="./termsOfUse.html" underline="hover" color="primary">
        Terms of Use
      </Link>{' '}
      {' and '}
      <Link href="./privPolicy.html" underline="hover" color="primary">
        Privacy Policy
      </Link>
      {'.'}
    </Typography>
  );
}

const SignUp = ({
  registerUser,
  setRegisterUser,
  handleSignInClick,
  errorMessage,
  setErrorMessage,
  buttonLoading,
}) => {
  const [errors, setErrors] = useState({
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    letter: false,
    number: false,
    specialChar: false,
    validChars: true,
  });

  const { register } = useContext(AuthContext);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const criteria = {
      length: password.length >= 8,
      letter: /[A-Za-z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[!@#$%^&*]/.test(password),
      validChars: /^[A-Za-z\d!@#$%^&*]*$/.test(password),
    };
    setPasswordCriteria(criteria);
    const strength =
      Object.values(criteria).reduce((acc, curr) => acc + (curr ? 1 : 0), 0) *
      20;
    setPasswordStrength(strength);
    return (
      criteria.length &&
      criteria.letter &&
      criteria.number &&
      criteria.specialChar &&
      criteria.validChars
    );
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let formErrors = {};

    if (!registerUser.emailId) {
      formErrors.email = 'Email is required';
    } else if (!validateEmail(registerUser.emailId)) {
      formErrors.email = 'Invalid email address';
    }

    if (!registerUser.password) {
      formErrors.password = 'Password is required';
    } else if (!validatePassword(registerUser.password)) {
      formErrors.password = 'Password does not meet the required criteria.';
    }

    if (!registerUser.confirmPassword) {
      formErrors.confirmPassword = 'Please confirm your password';
    } else if (registerUser.password !== registerUser.confirmPassword) {
      formErrors.confirmPassword = 'Passwords do not match';
    }

    if (Object.keys(formErrors).length === 0) {
      try {
        await register(registerUser);
      } catch (error) {
        setErrorMessage(
          error.message || 'Failed to register. Please try again.'
        );
      }
    } else {
      setErrors(formErrors);
    }
  };

  const handleError = () => {
    if (errorMessage) setErrorMessage('');
    setErrors({ email: '', password: '', confirmPassword: '' });
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100%',
        padding: { xs: 2, sm: 2 },
        boxShadow: '0 10px 30px rgba(0, 0, 0, 0.3)',
        borderRadius: 3,
        backgroundImage:
          'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
      }}
    >
      {/* Logo Container */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 1,
          mt: { xs: 0, sm: 1 },
          backgroundColor: colors.primary,
          boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
          borderRadius: 2,
          padding: 1,
          width: 'fit-content',
        }}
      >
        <img
          src={logoimg}
          alt="logo"
          style={{
            maxWidth: '80px',
            height: '35px',
            display: 'block',
          }}
        />
      </Box>

      {/* Content Container */}
      <Box
        sx={{
          background: 'transparent',
          padding: { xs: 0, sm: 2 },
          flexDirection: 'column',
          width: '100%',
          maxWidth: '600px',
        }}
      >
        <Box sx={{ textAlign: 'center', mb: 2 }}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 'bold',
              background: colors.signInText,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              textAlign: 'center',
              marginBottom: 0.5,
              fontSize: { xs: '1.5rem', sm: '2rem' },
            }}
          >
            Create your account!
          </Typography>
          <Typography
            variant="body2"
            sx={{
              marginBottom: 3,
              textAlign: 'center',
              color: colors.text,
              fontSize: { xs: '0.9rem', sm: '0.95rem' },
            }}
          >
            Already have an account?{' '}
            <Link
              component={RouterLink}
              to="/signin"
              sx={{
                fontWeight: 'bold',
                color: colors.signUpText,
                cursor: 'pointer',
                '&:hover': { textDecoration: 'underline', color: '#0d47a1' },
              }}
            >
              Sign in
            </Link>
          </Typography>
        </Box>

        {/* Form */}
        <Box
          component="form"
          noValidate
          onSubmit={handleFormSubmit}
          sx={{ width: '100%' }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                component="label"
                htmlFor="email"
                marginLeft={1}
                sx={{
                  display: 'block',
                  mb: 1,
                  fontSize: '0.95rem',
                  fontWeight: 500,
                  color: 'text.primary',
                }}
              >
                Email
              </Typography>
              <TextField
                required
                fullWidth
                id="email"
                placeholder="Enter your email"
                name="email"
                autoComplete="email"
                error={Boolean(errors.email)}
                helperText={errors.email}
                onChange={(event) => {
                  setRegisterUser({
                    ...registerUser,
                    emailId: event.target.value,
                  });
                  handleError();
                }}
                size="small"
                variant="outlined"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: 2,
                    backgroundColor: colors.PasswordBackground,
                    transition: 'all 0.2s',
                    '&:hover': {
                      backgroundColor: colors.PasswordHover,
                    },
                  },
                  '&.Mui-focused': {
                    backgroundColor: colors.white,
                    boxShadow: '0 0 0 2px rgba(21, 101, 192, 0.1)',
                  },
                  '& .MuiInputLabel-root': {
                    fontSize: '0.9rem',
                  },
                  '& .MuiInputBase-input': {
                    fontSize: '0.9rem',
                    padding: '8px 10px',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <SubmitPasswordForm
                handleError={handleError}
                errors={errors}
                registerUser={registerUser}
                setRegisterUser={setRegisterUser}
                validatePassword={validatePassword}
                passwordStrength={passwordStrength}
                passwordCriteria={passwordCriteria}
              />
            </Grid>
          </Grid>
          {errorMessage && (
            <Alert severity="error" sx={{ mt: 3 }}>
              {errorMessage}
            </Alert>
          )}
          <SButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              marginTop: 4,
              marginBottom: 2,
              padding: '8px',
              fontSize: '1rem',
              borderRadius: 2,
              fontWeight: 600,
              background: 'linear-gradient(45deg, #1565C0 30%, #0D47A1 90%)',
              transition: 'all 0.3s ease',
              textTransform: 'none',
              letterSpacing: '0.5px',
              '&:hover': {
                background: 'linear-gradient(45deg, #1976D2 30%, #1565C0 90%)',
                transform: 'translateY(-2px)',
              },
              '&:active': {
                transform: 'translateY(0)',
              },
              '&:disabled': {
                background: 'linear-gradient(45deg, #90CAF9 30%, #64B5F6 90%)',
                boxShadow: 'none',
              },
            }}
            disabled={buttonLoading}
            startIcon={
              buttonLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : null
            }
          >
            {buttonLoading ? '' : 'Get Started'}
          </SButton>
        </Box>
      </Box>
      <Copyright sx={{ mb: 3 }} />
    </Container>
  );
};
export default SignUp;
