import React from 'react';
import { useMediaQuery } from '@mui/material';
import {
  TabularContainer,
  SectionHeader,
  FlexItem,
  LineItemContainer,
  TotalLineContainer,
} from './styled';
import { addCommasToNumber } from './addCommasToNumber';

const FinancialStatement = ({
  incomeData,
  costOfGoodsSoldData,
  operatingExpensesData,
  totalIncome,
  totalCostOfGoodsSold,
  grossProfit,
  grossProfitPercentage,
  totalOperatingExpenses,
  netProfit,
  netProfitPercentage,
}) => {
  const isVerySmallScreen = useMediaQuery('(max-width:374px)');

  return (
    <TabularContainer>
      <FinancialSection title="Income" isSmallScreen={isVerySmallScreen}>
        {incomeData.map((item) => (
          <LineItem
            key={item.label}
            label={item.label}
            value={`$${addCommasToNumber(item.value)}`}
            isSmallScreen={isVerySmallScreen}
          />
        ))}
        <TotalLine
          label="Total Income"
          value={`$${addCommasToNumber(totalIncome)}`}
          isSmallScreen={isVerySmallScreen}
        />
      </FinancialSection>

      <FinancialSection
        title="Cost of Goods Sold"
        isSmallScreen={isVerySmallScreen}
      >
        {costOfGoodsSoldData.map((item) => (
          <LineItem
            key={item.label}
            label={item.label}
            value={`$${addCommasToNumber(item.value)}`}
            isSmallScreen={isVerySmallScreen}
          />
        ))}
        <TotalLine
          label="Total Cost of Goods Sold"
          value={`$${addCommasToNumber(totalCostOfGoodsSold)}`}
          isSmallScreen={isVerySmallScreen}
        />
      </FinancialSection>

      <ProfitSection
        title="Gross Profit"
        value={`$${addCommasToNumber(grossProfit)}`}
        percentage={grossProfitPercentage}
        isSmallScreen={isVerySmallScreen}
      />

      <br />

      <FinancialSection
        title="Operating Expenses"
        isSmallScreen={isVerySmallScreen}
      >
        {operatingExpensesData.map((item) => (
          <LineItem
            key={item.label}
            label={item.label}
            value={`$${addCommasToNumber(item.value)}`}
            isSmallScreen={isVerySmallScreen}
          />
        ))}
        <TotalLine
          label="Total Operating Expenses"
          value={`$${addCommasToNumber(totalOperatingExpenses)}`}
          isSmallScreen={isVerySmallScreen}
        />
      </FinancialSection>

      <ProfitSection
        title="Net Profit"
        value={`$${addCommasToNumber(netProfit)}`}
        percentage={netProfitPercentage}
        isNegative={netProfit < 0}
        isSmallScreen={isVerySmallScreen}
      />
    </TabularContainer>
  );
};

const FinancialSection = ({ title, children, isSmallScreen }) => (
  <>
    <SectionHeader style={isSmallScreen ? { fontSize: '1rem' } : {}}>
      {title}
    </SectionHeader>
    {children}
  </>
);

const LineItem = ({ label, value, isSmallScreen }) => (
  <LineItemContainer style={isSmallScreen ? { fontSize: '0.9rem' } : {}}>
    <span>{label}</span>
    <span>{value}</span>
  </LineItemContainer>
);

const TotalLine = ({ label, value, isSmallScreen }) => (
  <TotalLineContainer style={isSmallScreen ? { fontSize: '0.9rem' } : {}}>
    <span>{label}</span>
    <span>{value}</span>
  </TotalLineContainer>
);

const ProfitSection = ({
  title,
  value,
  percentage,
  isNegative = false,
  isSmallScreen,
}) => (
  <SectionHeader>
    <FlexItem
      justify="space-between"
      style={isSmallScreen ? { fontSize: '1rem' } : {}}
    >
      <span>{title}</span>
      <div>
        <span className={isNegative ? 'negative' : ''}>{value}</span>
      </div>
    </FlexItem>
    <FlexItem
      justify="space-between"
      style={isSmallScreen ? { fontSize: '0.8rem' } : {}}
    >
      <span style={{ fontWeight: 'normal' }}>
        As a percentage of Total Income
      </span>
      <div style={{ fontWeight: 'normal' }}>{percentage}</div>
    </FlexItem>
  </SectionHeader>
);

export default FinancialStatement;
