import React from 'react';
import {
  Box,
  Typography,
  Container,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { colors } from '../../../themes/theme';

const Footer = () => {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: colors.primary,
        color: 'white',
        py: 2,
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            flexDirection: isPhone ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: isPhone ? 1 : 2,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              fontSize: isPhone ? '0.75rem' : '1rem',
            }}
          >
            Copyright {new Date().getFullYear()} ONEBIT INC. | All Rights
            Reserved
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
