import ReactGA from 'react-ga4';
import { CircularProgress, Box, useMediaQuery, useTheme } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import dayjs from 'dayjs';
import ReportFilter from './ReportFilter';
import {
  Container,
  ContainerBody,
  ContainerHeader,
  FlexItem,
  Title,
  Text,
  ExportButton,
} from './styled';
import FinancialStatement from './FinancialStatement';
import useDateRange from '../../common/hooks/useDateRange';
import useReportData from '../../common/hooks/useReportData';
import utc from 'dayjs/plugin/utc';
import { useDashboardContext } from '../../common/contexts/DashboardContext.js';
import { useEffect, useRef, useCallback, useState } from 'react';

dayjs.extend(utc);

const ReportsPage = () => {
  const {
    setStartDate: setContextStartDate,
    setEndDate: setContextEndDate,
    minDateRangeLocal,
    maxDateRangeLocal,
  } = useDashboardContext();

  // Use local state for dates
  const [startDate, setLocalStartDate] = useState(
    dayjs().startOf('year').toDate()
  );
  const [endDate, setLocalEndDate] = useState(dayjs().toDate());

  // Sync functions to update both local and context state
  const setStartDate = useCallback(
    (date) => {
      setLocalStartDate(date);
      setContextStartDate(date);
    },
    [setContextStartDate]
  );

  const setEndDate = useCallback(
    (date) => {
      setLocalEndDate(date);
      setContextEndDate(date);
    },
    [setContextEndDate]
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const didInitialize = useRef(false);
  const { formatDate } = useDateRange(minDateRangeLocal, maxDateRangeLocal);

  const getInitialDateRange = useCallback(() => {
    const currentDate = dayjs();
    const yearStart = currentDate.startOf('year');

    return {
      start: yearStart.toDate(),
      end: currentDate.endOf('day').toDate(),
    };
  }, []);

  useEffect(() => {
    ReactGA.event({
      category: 'Reports',
      action: 'Page View',
      label: isMobile ? 'Mobile' : 'Desktop',
    });
  }, [isMobile]);

  useEffect(() => {
    if (!didInitialize.current) {
      const { start, end } = getInitialDateRange();
      setStartDate(start);
      setEndDate(end);
      didInitialize.current = true;
      ReactGA.event({
        category: 'Reports',
        action: 'Initialize Date Range',
        label: `${start} to ${end}`,
      });
    }
  }, [setStartDate, setEndDate, getInitialDateRange]);

  const { reportsData, handleExport, loading } = useReportData({
    startDate: dayjs.utc(startDate).format('YYYY-MM-DDTHH:mm:ssZ'),
    endDate: dayjs.utc(endDate).format('YYYY-MM-DDTHH:mm:ssZ'),
  });

  const handleReset = () => {
    const { start, end } = getInitialDateRange();
    setStartDate(start);
    setEndDate(end);
    ReactGA.event({
      category: 'Reports',
      action: 'Reset Date Range',
      label: 'Reset to Default Range',
    });
  };

  const trackExport = () => {
    ReactGA.event({
      category: 'Reports',
      action: 'Export Report',
      label: `${formatDate(startDate)} to ${formatDate(endDate)}`,
    });
    handleExport();
  };

  const isInitialDateRange = () => {
    if (!startDate || !endDate) return true;
    const { start: initialStart, end: initialEnd } = getInitialDateRange();
    return (
      dayjs(startDate).isSame(dayjs(initialStart), 'day') &&
      dayjs(endDate).isSame(dayjs(initialEnd), 'day')
    );
  };

  if (loading) return <Spinner />;

  const {
    income,
    costOfGoodsSold,
    operatingExpenses,
    netProfit,
    incomeData,
    costOfGoodsSoldData,
    operatingExpensesData,
    totalIncome,
    totalCostOfGoodsSold,
    totalOperatingExpenses,
    grossProfit,
  } = reportsData;

  const mobileHeader = (
    <ContainerHeader>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Title>Profit &amp; Loss</Title>
      </Box>
    </ContainerHeader>
  );

  const desktopHeader = (
    <ContainerHeader>
      <FlexItem>
        <Title>Profit &amp; Loss</Title>
      </FlexItem>
    </ContainerHeader>
  );

  const mobileAccountsHeader = (
    <ContainerHeader>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 1,
        }}
      >
        <Title>Accounts</Title>
        <Text>
          {formatDate(startDate)} to {formatDate(endDate)}
        </Text>
      </Box>
      <ExportButton
        variant="contained"
        color="primary"
        onClick={trackExport}
        startIcon={<ShareIcon />}
      >
        Export
      </ExportButton>
    </ContainerHeader>
  );

  const desktopAccountsHeader = (
    <ContainerHeader>
      <FlexItem>
        <Title>Accounts</Title>
      </FlexItem>
      <FlexItem dir="column">
        <Text>{formatDate(startDate)}</Text>
        <Text>to {formatDate(endDate)}</Text>
      </FlexItem>
    </ContainerHeader>
  );

  return (
    <>
      <Container>
        {isMobile ? mobileHeader : desktopHeader}
        <ContainerBody>
          <ReportFilter
            startDate={startDate}
            setStartDate={(date) => {
              setStartDate(date);
              ReactGA.event({
                category: 'Reports',
                action: 'Update Start Date',
                label: formatDate(date),
              });
            }}
            endDate={endDate}
            setEndDate={(date) => {
              setEndDate(date);
              ReactGA.event({
                category: 'Reports',
                action: 'Update End Date',
                label: formatDate(date),
              });
            }}
            income={income}
            costOfGoodsSold={costOfGoodsSold}
            operatingExpenses={operatingExpenses}
            netProfit={netProfit}
            minDateRangeLocal={minDateRangeLocal}
            maxDateRangeLocal={maxDateRangeLocal}
            handleReset={handleReset}
            isResetDisabled={isInitialDateRange()}
          />
        </ContainerBody>
        {!isMobile && (
          <ContainerHeader>
            <FlexItem justify="end">
              <ExportButton
                variant="contained"
                onClick={trackExport}
                startIcon={<ShareIcon />}
              >
                Export
              </ExportButton>
            </FlexItem>
          </ContainerHeader>
        )}
      </Container>
      <Container>
        {isMobile ? mobileAccountsHeader : desktopAccountsHeader}
        <ContainerBody padding="0px">
          <FinancialStatement
            incomeData={incomeData}
            costOfGoodsSoldData={costOfGoodsSoldData}
            operatingExpensesData={operatingExpensesData}
            totalIncome={'$' + totalIncome}
            totalCostOfGoodsSold={'$' + totalCostOfGoodsSold}
            grossProfit={'$' + grossProfit}
            grossProfitPercentage={
              parseInt(totalIncome) === 0
                ? 'N/A '
                : ((grossProfit / totalIncome) * 100).toFixed(2) + '%'
            }
            totalOperatingExpenses={'$' + totalOperatingExpenses}
            netProfit={'$' + netProfit.toFixed(2)}
            netProfitPercentage={
              parseInt(totalIncome) === 0
                ? 'N/A'
                : ((netProfit / totalIncome) * 100).toFixed(2) + '%'
            }
          />
        </ContainerBody>
      </Container>
    </>
  );
};

const Spinner = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100vw',
      height: 'calc(100vh - 100px)',
    }}
  >
    <CircularProgress />
  </div>
);

export default ReportsPage;
