import React, { useMemo, useState, useCallback, useEffect } from 'react';
import {
  Box,
  Tab,
  MenuItem,
  useMediaQuery,
  useTheme,
  Checkbox,
  Snackbar,
  Alert,
  CardContent,
  Stack,
  Typography,
  IconButton,
  Tooltip,
  InputAdornment,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import dayjs from 'dayjs';
import { RestartAlt as RestartAltIcon } from '@mui/icons-material';
import {
  DateControls,
  MetricCard,
  isDefaultDateRange,
} from './shared-components';
import { CustomTabs, StyledSelect } from '../../styles/style.js';
import { colors } from '../../../themes/theme.js';
import { useFinancialMetrics } from '../../common/hooks/useFinancialMetrics.js';
import { useDashboardContext } from '../../common/contexts/DashboardContext.js';
import {
  generateWeekOptions,
  generateMonthOptions,
  generateQuarterOptions,
  generateYearOptions,
  handleWeekSelectionChange,
  handleMonthSelectionChange,
  handleQuarterSelectionChange,
  handleYearSelectionChange,
} from './dateOptions';
import { useDateState } from '../../common/hooks/useDateState.js';
import MobileMetricsDisplay from './MobileMetricsDisplay.js';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker';

const InlineDateRangePicker = ({
  startDate,
  endDate,
  minDateRange,
  maxDateRange,
  onDateChange,
  isCustomRange,
}) => {
  const commonStyles = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '24px',
    },
    '& input': {
      textAlign: 'center',
    },
    '& .MuiInputAdornment-root': {
      marginRight: '8px',
    },
  };
  const commonInputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <CalendarTodayIcon />
      </InputAdornment>
    ),
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={1}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
            margin: 0,
            padding: 0,
            color: 'text.secondary',
          }}
        >
          <Typography variant="body2" sx={{ fontSize: '0.8rem' }}>
            Click below to select a custom date range.
          </Typography>
        </Box>

        <DesktopDateRangePicker
          value={[dayjs(startDate), dayjs(endDate)]}
          onChange={(newValue) => {
            if (newValue[0]?.isValid() && newValue[1]?.isValid()) {
              onDateChange(
                newValue[0].format('YYYY-MM-DD'),
                newValue[1].format('YYYY-MM-DD')
              );
            }
          }}
          minDate={dayjs(minDateRange)}
          maxDate={dayjs(maxDateRange)}
          calendars={2}
          slotProps={{
            textField: {
              InputProps: commonInputProps,
              size: 'small',
              sx: {
                ...commonStyles,
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  ...commonStyles['& .MuiOutlinedInput-root'],
                  ...(isCustomRange && {
                    '& fieldset': {
                      borderColor: colors.secondary,
                    },
                    '&:hover fieldset': {
                      borderColor: colors.secondary,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: colors.secondary,
                    },
                  }),
                },
                '& input': {
                  ...commonStyles['& input'],
                  fontSize: '0.875rem',
                },
              },
            },
            popper: {
              sx: {
                '& .MuiPaper-root': {
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 0,
                },
                '& .MuiPickersLayout-root': {
                  display: 'flex',
                  flexDirection: 'row',
                },
                '& .MuiPickersLayout-shortcuts': {
                  order: -1,
                },
              },
            },
          }}
          format="MMM DD, YYYY"
        />
      </Stack>
    </LocalizationProvider>
  );
};

const MetricsDisplay = React.memo(
  ({ transactions, onTimePeriodChange, filterState }) => {
    // Context and state management hooks
    const {
      activeTimeTab,
      setActiveTimeTab,
      selectedWeeks,
      setSelectedWeeks,
      selectedMonths,
      setSelectedMonths,
      selectedQuarters,
      setSelectedQuarters,
      selectedYears,
      setSelectedYears,
      startDate,
      setStartDate,
      endDate,
      setEndDate,
      minDateRangeLocal,
      maxDateRangeLocal,
    } = useDashboardContext();

    const dateState = useDateState();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isMdOrBelow = useMediaQuery(theme.breakpoints.down('md'));
    const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 700px)');

    // State management
    const [hoveredTab, setHoveredTab] = useState(null);
    const [isCustomDateRange, setIsCustomDateRange] = useState(false);
    const [mobileShortcut, setMobileShortcut] = useState(
      () => localStorage.getItem('lastMobileShortcut') || null
    );
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [hasClearedDesktopFilters, setHasClearedDesktopFilters] =
      useState(false);
    const [prevIsMdOrBelow, setPrevIsMdOrBelow] = useState(null);

    const handleSnackbarClose = useCallback(() => setSnackbarOpen(false), []);

    // Desktop filters clearing
    const clearDesktopFilters = useCallback(() => {
      // Clear all desktop-specific filters when switching to mobile view
      setSelectedWeeks([]);
      setSelectedMonths([]);
      setSelectedQuarters([]);
      setSelectedYears([]);
      setActiveTimeTab('all');
      setSnackbarMessage('Switched to mobile view, desktop filters cleared.');
      setSnackbarOpen(true);
      setHasClearedDesktopFilters(true);
      setIsCustomDateRange(false);
    }, [
      setSelectedWeeks,
      setSelectedMonths,
      setSelectedQuarters,
      setSelectedYears,
      setActiveTimeTab,
    ]);

    // Handle mobile/desktop view transitions
    useEffect(() => {
      // Check if this is the first render or a refresh
      if (prevIsMdOrBelow === null) {
        setPrevIsMdOrBelow(isMdOrBelow);
        return;
      }
      // Only clear filters and show alert when transitioning from desktop to mobile
      if (isMdOrBelow && !prevIsMdOrBelow && !hasClearedDesktopFilters) {
        clearDesktopFilters();
      } else if (!isMdOrBelow) {
        // Reset the flag when switching back to desktop
        setHasClearedDesktopFilters(false);
      }

      setPrevIsMdOrBelow(isMdOrBelow);
    }, [
      isMdOrBelow,
      clearDesktopFilters,
      hasClearedDesktopFilters,
      prevIsMdOrBelow,
    ]);

    // Handle page refresh
    useEffect(() => {
      const handleBeforeUnload = () => {
        localStorage.setItem('hasClearedDesktopFilters', 'false');
      };

      window.addEventListener('beforeunload', handleBeforeUnload);
      // Check if we're coming from a refresh
      const storedHasClearedDesktopFilters = localStorage.getItem(
        'hasClearedDesktopFilters'
      );
      if (storedHasClearedDesktopFilters === 'false') {
        setHasClearedDesktopFilters(false);
      }

      return () =>
        window.removeEventListener('beforeunload', handleBeforeUnload);
    }, []);

    // Handle backdrop clicks
    const handleClick = useCallback(
      (event) => {
        const targetClassName = event.target.className;
        const isTargetBackdrop =
          typeof targetClassName === 'string' &&
          targetClassName.includes('MuiBackdrop-root') &&
          targetClassName.includes('MuiBackdrop-invisible') &&
          targetClassName.includes('MuiModal-backdrop');
        // If click is outside the modal (on backdrop), close the date selectors
        if (isTargetBackdrop) {
          dateState.handleCloseWeek();
          dateState.handleCloseMonth();
          dateState.handleCloseQuarter();
          dateState.handleCloseYear();
          event.stopPropagation();
        }
      },
      [dateState]
    );

    // Generate time period options
    const weekOptions = useMemo(
      () =>
        generateWeekOptions(transactions, minDateRangeLocal, maxDateRangeLocal),
      [transactions, minDateRangeLocal, maxDateRangeLocal]
    );
    const monthOptions = useMemo(
      () =>
        generateMonthOptions(
          transactions,
          minDateRangeLocal,
          maxDateRangeLocal
        ),
      [transactions, minDateRangeLocal, maxDateRangeLocal]
    );
    const quarterOptions = useMemo(
      () =>
        generateQuarterOptions(
          transactions,
          minDateRangeLocal,
          maxDateRangeLocal
        ),
      [transactions, minDateRangeLocal, maxDateRangeLocal]
    );
    const yearOptions = useMemo(
      () =>
        generateYearOptions(transactions, minDateRangeLocal, maxDateRangeLocal),
      [transactions, minDateRangeLocal, maxDateRangeLocal]
    );

    // Filter transactions
    const filteredTransactions = useMemo(() => {
      if (filterState === 'income') {
        return transactions.filter((transaction) => transaction.amount >= 0);
      } else if (filterState === 'expenses') {
        return transactions.filter((transaction) => transaction.amount <= 0);
      }
      return transactions;
    }, [transactions, filterState]);

    const { income, expenses, netProfit } = useFinancialMetrics(
      filteredTransactions,
      activeTimeTab,
      selectedWeeks,
      selectedMonths,
      selectedQuarters,
      selectedYears,
      startDate,
      endDate
    );
    // Date range updates
    const updateDateRange = useCallback(() => {
      const minDate = dayjs(minDateRangeLocal);
      const maxDate = dayjs(maxDateRangeLocal);
      const updateRangeForPeriod = (selected, unit) => {
        if (selected.length > 0) {
          const selectedDates = selected
            .map((option) => {
              let start, end, year, q;
              switch (unit) {
                case 'week':
                  [start, end] = option.split(' - ');
                  // Get the year from the end date since it's the only one with year info
                  year = end.split(', ')[1];
                  // Add the year to the start date before parsing
                  start = dayjs(`${start}, ${year}`, 'MMM D, YYYY');
                  end = dayjs(end, 'MMM D, YYYY');
                  break;
                case 'month':
                  start = dayjs(option, 'MMMM YYYY').startOf('month');
                  end = dayjs(option, 'MMMM YYYY').endOf('month');
                  break;
                case 'quarter':
                  // Extract year and quarter from string like 'Q1 2023'
                  [q, year] = option.replace('Q', '').split(' ');
                  // Calculate start and end of quarter
                  start = dayjs(`${year}-${(q - 1) * 3 + 1}-01`).startOf(
                    'month'
                  );
                  end = dayjs(`${year}-${q * 3}-01`).endOf('month');
                  break;
                case 'year':
                  start = dayjs(option, 'YYYY').startOf('year');
                  end = dayjs(option, 'YYYY').endOf('year');
                  break;
                default:
                  console.warn(`Unknown unit: ${unit}`);
                  return null;
              }
              return { start, end };
            })
            .filter(Boolean);
          // Ensure selected date ranges don't exceed the available date range

          let newStartDate = dayjs.max(
            dayjs.min(...selectedDates.map((d) => d.start)),
            minDate
          );
          let newEndDate = dayjs.min(
            dayjs.max(...selectedDates.map((d) => d.end)),
            maxDate
          );
          // Handle case where start date is after end date (rare, but needed for edge cases)

          if (newStartDate.isAfter(newEndDate)) {
            newStartDate = newEndDate;
          }

          return { newStartDate, newEndDate };
        }
        return null;
      };

      let newDateRange;
      switch (activeTimeTab) {
        case 'week':
          newDateRange = updateRangeForPeriod(selectedWeeks, 'week');
          setIsCustomDateRange(true);
          break;
        case 'month':
          newDateRange = updateRangeForPeriod(selectedMonths, 'month');
          setIsCustomDateRange(true);
          break;
        case 'quarter':
          newDateRange = updateRangeForPeriod(selectedQuarters, 'quarter');
          setIsCustomDateRange(true);
          break;
        case 'year':
          newDateRange = updateRangeForPeriod(selectedYears, 'year');
          setIsCustomDateRange(true);
          break;
        case 'all':
        default:
          newDateRange = { newStartDate: minDate, newEndDate: maxDate };
          setIsCustomDateRange(false);
      }

      if (newDateRange) {
        setStartDate(newDateRange.newStartDate.format('YYYY-MM-DD'));
        setEndDate(newDateRange.newEndDate.format('YYYY-MM-DD'));
      }
    }, [
      activeTimeTab,
      selectedWeeks,
      selectedMonths,
      selectedQuarters,
      selectedYears,
      minDateRangeLocal,
      maxDateRangeLocal,
      setStartDate,
      setEndDate,
    ]);

    useEffect(() => {
      updateDateRange();
    }, [
      activeTimeTab,
      selectedWeeks,
      selectedMonths,
      selectedQuarters,
      selectedYears,
      minDateRangeLocal,
      maxDateRangeLocal,
      updateDateRange,
    ]);

    // Reset handlers
    const handleReset = useCallback(() => {
      setStartDate(dayjs(minDateRangeLocal).format('YYYY-MM-DD'));
      setEndDate(dayjs(maxDateRangeLocal).format('YYYY-MM-DD'));
      setIsCustomDateRange(false);
      setSelectedWeeks([]);
      setSelectedMonths([]);
      setSelectedQuarters([]);
      setSelectedYears([]);
      setActiveTimeTab('all');
      setMobileShortcut(null);
      localStorage.removeItem('lastMobileShortcut');
      onTimePeriodChange('all');
    }, [
      minDateRangeLocal,
      maxDateRangeLocal,
      setStartDate,
      setEndDate,
      setSelectedWeeks,
      setSelectedMonths,
      setSelectedQuarters,
      setSelectedYears,
      setActiveTimeTab,
      onTimePeriodChange,
    ]);

    const clearOtherSelections = useCallback(
      (currentTab) => {
        if (currentTab !== 'week') setSelectedWeeks([]);
        if (currentTab !== 'month') setSelectedMonths([]);
        if (currentTab !== 'quarter') setSelectedQuarters([]);
        if (currentTab !== 'year') setSelectedYears([]);
      },
      [
        setSelectedWeeks,
        setSelectedMonths,
        setSelectedQuarters,
        setSelectedYears,
      ]
    );

    // Tab change handler
    const handleTimeTabChange = useCallback(
      (event, newValue) => {
        setActiveTimeTab(newValue);
        if (newValue === 'all') {
          setIsCustomDateRange(
            !isDefaultDateRange(
              startDate,
              endDate,
              minDateRangeLocal,
              maxDateRangeLocal
            )
          );
          clearOtherSelections(newValue);
        } else {
          setIsCustomDateRange(newValue !== 'all');
          clearOtherSelections(newValue);
        }
        onTimePeriodChange(newValue);
        updateDateRange();
      },
      [
        setActiveTimeTab,
        onTimePeriodChange,
        updateDateRange,
        clearOtherSelections,
        startDate,
        endDate,
        minDateRangeLocal,
        maxDateRangeLocal,
      ]
    );

    // Custom date range handlers
    const handleCustomDateRangeSelect = useCallback(
      (newStartDate, newEndDate) => {
        setStartDate(newStartDate);
        setEndDate(newEndDate);

        const isDefault = isDefaultDateRange(
          newStartDate,
          newEndDate,
          minDateRangeLocal,
          maxDateRangeLocal
        );
        setIsCustomDateRange(!isDefault);

        if (activeTimeTab !== 'all') {
          setActiveTimeTab('all');
          clearOtherSelections('all');
          onTimePeriodChange('all');
        }
      },
      [
        setStartDate,
        setEndDate,
        setActiveTimeTab,
        clearOtherSelections,
        onTimePeriodChange,
        minDateRangeLocal,
        maxDateRangeLocal,
        activeTimeTab,
      ]
    );

    // Mobile shortcut handler
    const handleMobileShortcutSelect = useCallback(
      (shortcut) => {
        const maxDate = dayjs(maxDateRangeLocal);
        const minDate = dayjs(minDateRangeLocal);
        let newStartDate, newEndDate;

        switch (shortcut) {
          case '7days':
            newStartDate = dayjs.max(maxDate.subtract(6, 'day'), minDate);
            newEndDate = maxDate;
            setIsCustomDateRange(true);
            break;
          case '30days':
            newStartDate = dayjs.max(maxDate.subtract(29, 'day'), minDate);
            newEndDate = maxDate;
            setIsCustomDateRange(true);
            break;
          case '90days':
            newStartDate = dayjs.max(maxDate.subtract(89, 'day'), minDate);
            newEndDate = maxDate;
            setIsCustomDateRange(true);
            break;
          case '365days':
            newStartDate = dayjs.max(maxDate.subtract(364, 'day'), minDate);
            newEndDate = maxDate;
            setIsCustomDateRange(true);
            break;
          case 'all':
            newStartDate = minDate;
            newEndDate = maxDate;
            setIsCustomDateRange(false);
            break;
          case 'custom': // Date range picker
            setIsCustomDateRange(true);
            break;
          default:
            return;
        }

        if (shortcut !== 'custom') {
          setStartDate(newStartDate.format('YYYY-MM-DD'));
          setEndDate(newEndDate.format('YYYY-MM-DD'));
        }
        setMobileShortcut(shortcut);
        localStorage.setItem('lastMobileShortcut', shortcut);
      },
      [maxDateRangeLocal, minDateRangeLocal, setStartDate, setEndDate]
    );

    useEffect(() => {
      if (mobileShortcut && mobileShortcut !== 'all') {
        handleMobileShortcutSelect(mobileShortcut);
      }
    }, [handleMobileShortcutSelect, mobileShortcut]);

    // Tab content renderer
    const renderTabContent = useCallback(
      (label, isOpen, handleOver, selectedValues, options) => (
        <Tab
          sx={{
            width: 'fit-content',
            minWidth: 'unset',
            '&.MuiTab-root': {
              padding: 0,
            },
          }}
          label={
            <StyledSelect
              sx={{
                zIndex: hoveredTab === label ? 1301 : 'auto',
                width: '100%',
                minWidth: '100%',
                maxWidth: '100%',
                padding: 0,
              }}
              open={isOpen}
              onMouseEnter={() => {
                setHoveredTab(label);
                handleOver();
              }}
              multiple
              value={selectedValues}
              onChange={(event) => {
                switch (label) {
                  // Handle selection changes based on active tab (week, month, quarter, year)

                  case 'Week':
                    handleWeekSelectionChange(
                      event,
                      weekOptions,
                      setSelectedWeeks
                    );
                    break;
                  case 'Month':
                    handleMonthSelectionChange(
                      event,
                      monthOptions,
                      setSelectedMonths
                    );
                    break;
                  case 'Quarter':
                    handleQuarterSelectionChange(
                      event,
                      quarterOptions,
                      setSelectedQuarters
                    );
                    break;
                  case 'Year':
                    handleYearSelectionChange(
                      event,
                      yearOptions,
                      setSelectedYears
                    );
                    break;
                  default:
                    console.warn(`Unknown label: ${label}`);
                    break;
                }
                clearOtherSelections(label.toLowerCase());
                setIsCustomDateRange(true); // Set to true when selecting any period
              }}
              onClick={handleClick}
              renderValue={() => label}
              displayEmpty
              MenuProps={{
                PaperProps: {
                  onMouseLeave: () => {
                    dateState[`handleClose${label}`]();
                    setHoveredTab(null);
                  },
                  style: {
                    maxHeight: 200,
                    maxWidth: 300,
                    fontSize: '14px',
                  },
                },
                BackdropProps: {
                  invisible: true,
                },
                autoFocus: false,
              }}
            >
              {selectedValues?.length !== options?.length && (
                <MenuItem value="select-all">
                  <em>Select All</em>
                </MenuItem>
              )}
              {selectedValues?.length > 0 && (
                <MenuItem value="deselect-all">
                  <em>Deselect All</em>
                </MenuItem>
              )}
              {options.map((option) => (
                <MenuItem key={option} value={option}>
                  <Checkbox
                    sx={{ ml: -1 }}
                    checked={selectedValues.indexOf(option) > -1}
                  />
                  {option}
                </MenuItem>
              ))}
            </StyledSelect>
          }
          value={label.toLowerCase()}
        />
      ),
      [
        dateState,
        hoveredTab,
        clearOtherSelections,
        weekOptions,
        monthOptions,
        quarterOptions,
        yearOptions,
        setSelectedWeeks,
        setSelectedMonths,
        setSelectedQuarters,
        setSelectedYears,
        handleClick,
      ]
    );

    // Main render
    return (
      <>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="info"
            sx={{ width: '100%' }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>

        {!isMdOrBelow ? (
          // Desktop view
          <Box
            sx={{
              width: { xs: '92vw', sm: '95vw', md: '85vw', lg: '80vw' },
              height: 'fit-content',
              backgroundColor: 'white',
              mb: 0,
              pb: { xs: 3, sm: 3 },
              borderRadius: 8,
              boxShadow: colors.shadow,
              position: 'relative',
              zIndex: 0,
            }}
          >
            <Box
              sx={{
                width: '100%',
                position: 'relative',
                bottom: '15px',
                left: '35px',
              }}
            >
              <CustomTabs
                value={activeTimeTab}
                onChange={handleTimeTabChange}
                aria-label="time tabs"
                scrollButtons="auto"
                sx={{
                  '& .MuiTab-root': { minWidth: 'fit-content' },
                  cursor: 'pointer',
                }}
              >
                {renderTabContent(
                  'Week',
                  dateState.isOpenWeek,
                  dateState.handleOverWeek,
                  selectedWeeks,
                  weekOptions
                )}
                {renderTabContent(
                  'Month',
                  dateState.isOpenMonth,
                  dateState.handleOverMonth,
                  selectedMonths,
                  monthOptions
                )}
                {renderTabContent(
                  'Quarter',
                  dateState.isOpenQuarter,
                  dateState.handleOverQuarter,
                  selectedQuarters,
                  quarterOptions
                )}
                {renderTabContent(
                  'Year',
                  dateState.isOpenYear,
                  dateState.handleOverYear,
                  selectedYears,
                  yearOptions
                )}
                <Tab
                  label="All"
                  value="all"
                  style={{ fontSize: '14px', flexShrink: 0 }}
                />
              </CustomTabs>
            </Box>

            <CardContent sx={{ pb: '13px !important', pt: '0 !important' }}>
              <Stack>
                <DateControls>
                  <Box sx={{ flex: 1, mr: 1 }}>
                    <InlineDateRangePicker
                      startDate={startDate}
                      endDate={endDate}
                      minDateRange={minDateRangeLocal}
                      maxDateRange={maxDateRangeLocal}
                      onDateChange={handleCustomDateRangeSelect}
                      isCustomRange={isCustomDateRange}
                    />
                  </Box>
                  <Tooltip title="Reset date range" placement="top">
                    <span>
                      <IconButton
                        onClick={handleReset}
                        disabled={isDefaultDateRange(
                          startDate,
                          endDate,
                          minDateRangeLocal,
                          maxDateRangeLocal
                        )}
                        size="small"
                        sx={{
                          top: '11px',
                          backgroundColor: colors.secondary,
                          color: colors.white,
                          transition: 'all 0.2s ease',
                          '&:hover': {
                            backgroundColor: colors.hoverBg,
                            transform: 'scale(1.05)',
                          },
                          '&.Mui-disabled': {
                            backgroundColor:
                              theme.palette.action.disabledBackground,
                            color: theme.palette.action.disabled,
                          },
                        }}
                      >
                        <RestartAltIcon fontSize="small" />
                      </IconButton>
                    </span>
                  </Tooltip>
                </DateControls>

                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    pt: 2,
                  }}
                >
                  <MetricCard
                    label="INCOME"
                    amount={parseFloat(income.replace(/[^0-9.-]+/g, ''))}
                    type="positive"
                    isMobile={isMobile}
                    isTablet={isTablet}
                  />
                  <MetricCard
                    label="EXPENSES"
                    amount={parseFloat(expenses.replace(/[^0-9.-]+/g, ''))}
                    type="negative"
                    isMobile={isMobile}
                    isTablet={isTablet}
                  />
                  <MetricCard
                    label="NET POSITION"
                    amount={parseFloat(netProfit.replace(/[^0-9.-]+/g, ''))}
                    type={
                      parseFloat(netProfit.replace(/[^0-9.-]+/g, '')) >= 0
                        ? 'positive'
                        : 'negative'
                    }
                    isMobile={isMobile}
                    isTablet={isTablet}
                  />
                </Box>
              </Stack>
            </CardContent>
          </Box>
        ) : (
          // Mobile view
          <Box sx={{ paddingTop: '0px' }}>
            <MobileMetricsDisplay
              transactions={filteredTransactions}
              income={income}
              expenses={expenses}
              netProfit={netProfit}
              startDate={startDate}
              endDate={endDate}
              minDateRange={minDateRangeLocal}
              maxDateRange={maxDateRangeLocal}
              mobileShortcut={mobileShortcut}
              onMobileShortcutSelect={handleMobileShortcutSelect}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </Box>
        )}
      </>
    );
  }
);

export default MetricsDisplay;
