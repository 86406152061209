import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { colors } from '../../../../themes/theme';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import BarChartBars from './BarChartBars';
import Legend from './Legend';

dayjs.extend(isBetween);

const ScrollContainer = styled(Box)({
  width: '100%',
  overflowX: 'auto',
  overflowY: 'hidden',
  '&::-webkit-scrollbar': {
    height: 12,
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#888',
    borderRadius: 6,
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#555',
  },
});


const filterTransactionsByWeek = (transactions, selectedWeeks) => {
  return transactions.filter((transaction) => {
    const transactionDate = dayjs(transaction.authorized_date).local();
    return selectedWeeks.some((week) => {
      const [start, end] = week.split(' - ');
      const startDate = dayjs(`${start}, ${end.split(', ')[1]}`).startOf('day');
      const endDate = dayjs(end).endOf('day');
      return transactionDate.isBetween(startDate, endDate, null, '[]');
    });
  });
};

const filterTransactionsByMonth = (transactions, selectedMonths) => {
  return transactions.filter((transaction) => {
    const transactionDate = dayjs(transaction.authorized_date)
      .local()
      .format('MMMM YYYY');
    return selectedMonths.includes(transactionDate);
  });
};

const filterTransactionsByQuarter = (transactions, selectedQuarters) => {
  return transactions.filter((transaction) => {
    const transactionDate = dayjs(transaction.authorized_date).local();
    const quarter = `Q${transactionDate.quarter()} ${transactionDate.year()}`;
    return selectedQuarters.includes(quarter);
  });
};

const filterTransactionsByYear = (transactions, selectedYears) => {
  if (selectedYears.length === 1) {
    return transactions.filter((transaction) => {
      const transactionYear = dayjs(transaction.authorized_date).local().year();
      return transactionYear === parseInt(selectedYears[0]);
    });
  }
  return transactions.filter((transaction) => {
    const transactionDate = dayjs(transaction.authorized_date).local();
    return selectedYears.includes(transactionDate.year().toString());
  });
};

const filterTransactionsByDateRange = (transactions, startDate, endDate) => {
  return transactions.filter((transaction) => {
    const transactionDate = dayjs(transaction.authorized_date).local();
    const start = dayjs(startDate).startOf('day');
    const end = dayjs(endDate).endOf('day');
    return transactionDate.isBetween(start, end, null, '[]');
  });
};

const aggregateTransactionData = (
  filteredTransactions,
  timePeriod,
  selectedYears
) => {
  return filteredTransactions.reduce((acc, transaction) => {
    const amount = parseFloat(transaction.amount);
    let key;

    // Determine the aggregation key based on timePeriod
    switch (timePeriod) {
      case 'week':
        // For week view, show by day
        key = dayjs(transaction.authorized_date).format('MMM D');
        break;

      case 'month':
        // For month view, show by day
        key = dayjs(transaction.authorized_date).format('MMM');
        break;

      case 'quarter':
        // For quarter view, show by month within quarter
        key = dayjs(transaction.authorized_date).format('MMM');
        break;

      case 'year':
        // For year view, depends on selection
        if (selectedYears.length === 1) {
          // Single year - show by month
          key = dayjs(transaction.authorized_date).format('MMM');
        } else {
          // Multiple years - show by year
          key = dayjs(transaction.authorized_date).format('YYYY');
        }
        break;

      case 'all':
        // For all view, show by month
        key = dayjs(transaction.authorized_date).format('YYYY');
        break;

      default:
        key = dayjs(transaction.authorized_date).format('MMM');
    }

    if (!acc[key]) {
      acc[key] = {
        name: key,
        netAmount: 0,
        income: 0,
        expenses: 0,
      };
    }

    if (amount >= 0) {
      acc[key].income += amount;
    } else {
      acc[key].expenses += Math.abs(amount);
    }
    acc[key].netAmount += amount;

    return acc;
  }, {});
};

// sort the aggregated data
const sortAggregatedData = (data, timePeriod, selectedYears) => {
  return Object.values(data).sort((a, b) => {
    switch (timePeriod) {
      case 'week':
      case 'month':
      case 'quarter':
      case 'year':
        if (selectedYears.length === 1) {
          // Sort by month
          return dayjs(a.name, 'MMM').month() - dayjs(b.name, 'MMM').month();
        } else {
          // Sort by year
          return parseInt(a.name) - parseInt(b.name);
        }

      case 'all':
        // Sort by month and year
        return (
          dayjs(a.name, 'MMM YYYY').valueOf() -
          dayjs(b.name, 'MMM YYYY').valueOf()
        );

      default:
        return dayjs(a.name, 'MMM').month() - dayjs(b.name, 'MMM').month();
    }
  });
};


const BarChartRecharts = ({
  transactions,
  timePeriod = 'year',
  selectedWeeks = [],
  selectedMonths = [],
  selectedQuarters = [],
  selectedYears = [],
  startDate,
  endDate,
}) => {
  const [data, setData] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (!transactions?.length) return;

    let filteredTransactions;
    switch (timePeriod) {
      case 'week':
        filteredTransactions = filterTransactionsByWeek(
          transactions,
          selectedWeeks
        );
        break;
      case 'month':
        filteredTransactions = filterTransactionsByMonth(
          transactions,
          selectedMonths
        );
        break;
      case 'quarter':
        filteredTransactions = filterTransactionsByQuarter(
          transactions,
          selectedQuarters
        );
        break;
      case 'year':
        filteredTransactions = filterTransactionsByYear(
          transactions,
          selectedYears
        );
        break;
      case 'all':
        filteredTransactions = filterTransactionsByDateRange(
          transactions,
          startDate,
          endDate
        );
        break;
      default:
        filteredTransactions = transactions;
    }


    const aggregated = aggregateTransactionData(
      filteredTransactions,
      timePeriod,
      selectedYears
    );


    const transformedData = sortAggregatedData(
      aggregated,
      timePeriod,
      selectedYears
    );

    setData(transformedData);
  }, [
    transactions,
    timePeriod,
    selectedWeeks,
    selectedMonths,
    selectedQuarters,
    selectedYears,
    startDate,
    endDate,
  ]);

  if (!data?.length) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '200px',
          color: colors.lightGray3,
          textAlign: 'center',
        }}
      >
        No data available for this period
      </Box>
    );
  }

const calculatedTotals = data.reduce(
  (totals, item) => ({
    income: totals.income + item.income,
    expenses: totals.expenses + item.expenses,
  }),
  { income: 0, expenses: 0 }
);

  const dateRange = data.length
    ? `${data[0].name} - ${data[data.length - 1].name}`
    : '';

  return (
    <Container sx={{ padding: isSmallScreen ? 1 : 3 }}>
      <Typography
        variant={isSmallScreen ? 'subtitle2' : 'subtitle1'}
        align="left"
        sx={{
          color: colors.black,
          fontWeight: 'bold',
        }}
      >
        {calculatedTotals.income - calculatedTotals.expenses >= 0
          ? `Net Income ${dateRange}`
          : `Net Loss ${dateRange}`}
      </Typography>

      <Typography
        variant={isSmallScreen ? 'h5' : 'h4'}
        align="left"
        gutterBottom
        sx={{
          color:
            calculatedTotals.income - calculatedTotals.expenses >= 0
              ? colors.income
              : colors.expenses,
          fontWeight: 'bold',
        }}
      >
        $
        {Math.abs(
          calculatedTotals.income - calculatedTotals.expenses
        ).toLocaleString()}
      </Typography>
      {/* Income and Expense Breakdown */}
      <Typography
        variant="body2"
        align="left"
        sx={{
          color: 'text.secondary',
          marginBottom: 1,
          fontSize: isSmallScreen ? '0.875rem' : '1rem',
        }}
      >
        Income: ${calculatedTotals.income.toLocaleString()} | Spent: $
        {calculatedTotals.expenses.toLocaleString()}
      </Typography>

      {/* Bar Chart */}
      <Box
        sx={{
          mt: 2,
          marginLeft: isSmallScreen ? -2 : 0,
          maxWidth: '100%',
        }}
      >
        <ScrollContainer>
          <BarChartBars data={data} />
        </ScrollContainer>
      </Box>

      {/* Legend */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Legend />
      </Box>
    </Container>
  );
};

export default BarChartRecharts;
