import * as React from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { useStore } from '@tanstack/react-store';
import { store, updateStore } from '../../../data/store';
import { Stack } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const AiSwitch = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 28,
  padding: 8,
  [theme.breakpoints.down('sm')]: {
    width: 45,
    height: 25,
    padding: 6,
  },
  '& .MuiSwitch-switchBase': {
    margin: 0,
    padding: 3,
    transform: 'translateX(5px)',
    [theme.breakpoints.down('sm')]: {
      padding: 2,
      transform: 'translateX(4px)',
    },
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& + .MuiSwitch-track': {
        background: 'linear-gradient(45deg, #ff6482, #5aa8ff)',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    background: 'linear-gradient(45deg, #000, #000)',
    width: 20,
    height: 20,
    [theme.breakpoints.down('sm')]: {
      width: 20,
      height: 20,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#aab4be',
    borderRadius: 20 / 2,
  },
}));

export default function ToggleAiSearch() {
  const enableAiSearch = useStore(store, (state) => state.enableAiSearch);
  return (
    <FormGroup>
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        <AutoAwesomeIcon
          sx={{
            fill: enableAiSearch ? 'url(#iconGradient)' : 'gray',
            fontSize: { xs: '20px', sm: '20px' },
          }}
        />
        <Typography
          variant="body1"
          sx={{
            fontSize: {
              xs: '0.875rem',
              sm: '0.9rem',
              fontWeight: 'bold',
              background: 'linear-gradient(45deg, #5aa8ff, #ff6482)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            },
          }}
        >
          AI Search
        </Typography>
        <AiSwitch
          checked={enableAiSearch}
          onChange={() => updateStore({ enableAiSearch: !enableAiSearch })}
          inputProps={{ 'aria-label': 'AI Search Toggle' }}
        />
      </Stack>
    </FormGroup>
  );
}
