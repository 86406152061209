import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import { DashboardProvider } from './components/common/contexts/DashboardContext';
import ErrorBoundary from './components/errorHandling/ErrorBoundary';
import { AuthProvider } from './components/common/contexts/authContext';
import * as Sentry from '@sentry/react';
import { LicenseInfo } from '@mui/x-license';
import { WebSocketProvider } from './components/common/contexts/WebSocketContext';
import { QueryClientProvider } from '@tanstack/react-query';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import queryClient from './lib/queryClient';

persistQueryClient({
  queryClient: queryClient,
  persister: createSyncStoragePersister({
    storage: window.localStorage,
  }),
});

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);
const wsUrl = process.env.REACT_APP_WS_URL;
const userId = localStorage.getItem('userId');

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  beforeSend(event, hint) {
    if (process.env.NODE_ENV === 'development') {
      console.log(
        'Sentry event in development (not sent but logging):\n',
        event,
        hint
      );
      return null;
    }
    return event;
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundary>
    <React.Fragment>
      <CssBaseline />
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <DashboardProvider>
              <WebSocketProvider wsUrl={wsUrl} userId={userId}>
                <App />
              </WebSocketProvider>
            </DashboardProvider>
          </AuthProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </React.Fragment>
  </ErrorBoundary>
);
