const groupedCategories = {
  'Operating Expenses': [
    {
      label: 'Operating Expenses',
      value: 'OPERATING_EXPENSES',
      abbrv: 'Oper. Exp.',
    },
    {
      value: 'RENT_EXPENSE',
      label: 'Rent Expense',
      abbrv: 'Rent Expense',
    },
    {
      value: 'REPAIRS_MAINTENANCE',
      label: 'Repairs & Maintenance',
      abbrv: 'Repairs & Maint.',
    },
    {
      value: 'UTILITIES',
      label: 'Utilities',
      abbrv: 'Utilities',
    },
    {
      value: 'TELEPHONE_LAND_LINE',
      label: 'Telephone - Land Line',
      abbrv: 'Telephone',
    },
  ],
  'Professional Services': [
    {
      value: 'ACCOUNTING_FEES',
      label: 'Accounting Fees',
      abbrv: 'Acct. Fees',
    },
    {
      value: 'PROFESSIONAL_FEES',
      label: 'Professional Fees',
      abbrv: 'Prof. Fees',
    },
    {
      value: 'DUES_SUBSCRIPTIONS',
      label: 'Dues & Subscriptions',
      abbrv: 'Dues & Subs.',
    },
    {
      value: 'BANK_SERVICE_CHARGES',
      label: 'Bank Service Charges',
      abbrv: 'Bank Svc. Charges',
    },
    {
      value: 'BUSINESS_LICENSES_PERMITS',
      label: 'Business Licenses & Permits',
      abbrv: 'Licenses & Permits',
    },
  ],
  Technology: [
    {
      value: 'COMPUTER_HARDWARE',
      label: 'Computer - Hardware',
      abbrv: 'Comp. Hardware',
    },
    {
      value: 'COMPUTER_HOSTING',
      label: 'Computer - Hosting',
      abbrv: 'Comp. Hosting',
    },
    {
      value: 'COMPUTER_INTERNET',
      label: 'Computer - Internet',
      abbrv: 'Comp. Internet',
    },
    {
      value: 'COMPUTER_SOFTWARE',
      label: 'Computer - Software',
      abbrv: 'Comp. Software',
    },
  ],
  'Marketing & Advertising': [
    {
      value: 'ADVERTISING_PROMOTION',
      label: 'Advertising & Promotion',
      abbrv: 'Adv. & Promo.',
    },
    {
      value: 'EVENT_FEES',
      label: 'Event Fees',
      abbrv: 'Event Fees',
    },
    {
      value: 'PRODUCT_SAMPLES',
      label: 'Product Samples',
      abbrv: 'Prod. Samples',
    },
  ],
  'Travel & Transportation': [
    {
      value: 'TRAVEL_EXPENSE',
      label: 'Travel Expense',
      abbrv: 'Travel',
    },
    {
      value: 'VEHICLE_FUEL',
      label: 'Vehicle - Fuel',
      abbrv: 'Vehicle Fuel',
    },
    {
      value: 'VEHICLE_REPAIRS_MAINTENANCE',
      label: 'Vehicle - Repairs & Maintenance',
      abbrv: 'Vehicle Repairs',
    },
    {
      value: 'INSURANCE_VEHICLES',
      label: 'Insurance - Vehicles',
      abbrv: 'Ins. Vehicles',
    },
  ],
  'Office & Supplies': [
    {
      value: 'OFFICE_SUPPLIES',
      label: 'Office Supplies',
      abbrv: 'Office Supplies',
    },
    {
      value: 'FOOD_COST',
      label: 'Food Cost',
      abbrv: 'Food Cost',
    },
  ],
  'Payroll & Taxes': [
    {
      value: 'PAYROLL_SALARY_WAGES',
      label: 'Payroll - Salary & Wages',
      abbrv: 'Payroll',
    },
    {
      value: 'TAXES',
      label: 'Taxes - Capital gains, payroll, income and sales taxes',
      abbrv: 'Taxes',
    },
  ],
  'Purchases & Materials': [
    {
      value: 'PURCHASES_PARTS_MATERIALS',
      label: 'Purchases - Parts & Materials',
      abbrv: 'Purch. Parts & Mat.',
    },
    {
      value: 'PURCHASES_RESALE_ITEMS',
      label: 'Purchases - Resale Items',
      abbrv: 'Purch. Resale Items',
    },
    {
      label: 'Cost of Goods',
      value: 'COST_OF_GOODS',
      abbrv: 'COGS',
    },
  ],
  'Entertainment & Miscellaneous': [
    {
      value: 'MEALS_ENTERTAINMENT',
      label: 'Meals and Entertainment',
      abbrv: 'Meals & Ent.',
    },
    {
      value: 'UNCATEGORIZED_EXPENSE',
      label: 'Uncategorized Expense',
      abbrv: 'Uncategorized Exp.',
    },
  ],
};

const flatCategories = Object.values(groupedCategories).flat();

export { groupedCategories, flatCategories };
