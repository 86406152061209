import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { PASSWORD_CRITERIA } from '../../data/constants';
import { styled } from '@mui/material';
import { colors } from '../../themes/theme';

function SubmitPasswordForm({
  handleError,
  errors,
  registerUser,
  setRegisterUser,
  validatePassword,
  passwordStrength,
  passwordCriteria,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isPasswordFocused, setIsPasswordFocused] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = (event) => event.preventDefault();

  const getStrengthColor = (strength) => {
    if (strength < 50) return 'red';
    if (strength < 75) return 'orange';
    return 'green';
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            component="label"
            htmlFor="email"
            marginLeft={1}
            sx={{
              display: 'block',
              mb: 1,
              fontSize: '0.95rem',
              fontWeight: 500,
              color: 'text.primary',
            }}
          >
            Password
          </Typography>
          <TextField
            size="small"
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 2,
                backgroundColor: colors.PasswordBackground,
                transition: 'all 0.2s',
                '&:hover': {
                  backgroundColor: colors.PasswordHover,
                },
              },
              '&.Mui-focused': {
                backgroundColor: colors.white,
                boxShadow: '0 0 0 2px rgba(21, 101, 192, 0.1)',
              },
              '& .MuiInputLabel-root': {
                fontSize: '0.9rem',
              },
              '& .MuiInputBase-input': {
                fontSize: '0.9rem',
                padding: '8px 10px',
              },
            }}
            required
            fullWidth
            id="password"
            placeholder="Create a password"
            type={showPassword ? 'text' : 'password'}
            name="password"
            autoComplete="new-password"
            error={Boolean(errors.password)}
            helperText={errors.password}
            onChange={(event) => {
              const password = event.target.value;
              setRegisterUser({
                ...registerUser,
                password,
              });
              validatePassword(password);
              handleError();
            }}
            onFocus={() => setIsPasswordFocused(true)}
            onBlur={() => setIsPasswordFocused(false)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {isPasswordFocused && (
            <Box sx={{ mt: 1 }}>
              <LinearProgress
                variant="determinate"
                value={passwordStrength}
                sx={{
                  mt: 1,
                  bgcolor: 'lightgray',
                  '& .MuiLinearProgress-bar': {
                    bgcolor: getStrengthColor(passwordStrength),
                  },
                  height: 5,
                  borderRadius: 5,
                }}
              />
              <Typography variant="caption" color="text.secondary">
                Your password must meet the following criteria:
              </Typography>
              <ul style={{ listStyleType: 'none', padding: 10, margin: 0 }}>
                {Object.entries(PASSWORD_CRITERIA).map(([key, value]) => (
                  <li key={key}>
                    <PasswordCriteria
                      variant="caption"
                      color={passwordCriteria[key]}
                    >
                      {passwordCriteria[key] ? (
                        <CheckCircleIcon fontSize="small" sx={{ mr: 0.5 }} />
                      ) : (
                        <CancelIcon fontSize="small" sx={{ mr: 0.5 }} />
                      )}
                      {value}
                    </PasswordCriteria>
                  </li>
                ))}
                {!passwordCriteria.validChars && (
                  <li>
                    <PasswordCriteria variant="caption" color={false}>
                      <CancelIcon fontSize="small" sx={{ mr: 0.5 }} />
                      Password contains invalid characters! Valid characters
                      are: a-z, A-Z, 0-9, !@#$%^&*
                    </PasswordCriteria>
                  </li>
                )}
              </ul>
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography
            component="label"
            htmlFor="email"
            marginLeft={1}
            sx={{
              display: 'block',
              mb: 1,
              fontSize: '0.95rem',
              fontWeight: 500,
              color: 'text.primary',
            }}
          >
            Confirm Password
          </Typography>
          <TextField
            size="small"
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 2,
                backgroundColor: colors.PasswordBackground,
                transition: 'all 0.2s',
                '&:hover': {
                  backgroundColor: colors.PasswordHover,
                },
              },
              '&.Mui-focused': {
                backgroundColor: colors.white,
                boxShadow: '0 0 0 2px rgba(21, 101, 192, 0.1)',
              },
              '& .MuiInputLabel-root': {
                fontSize: '0.9rem',
              },
              '& .MuiInputBase-input': {
                fontSize: '0.9rem',
                padding: '8px 10px',
              },
            }}
            required
            fullWidth
            name="confirm_password"
            placeholder="Re-enter your
          password"
            type={showConfirmPassword ? 'text' : 'password'}
            id="confirm_password"
            autoComplete="new-password"
            error={Boolean(errors.confirmPassword)}
            helperText={errors.confirmPassword}
            onChange={(event) => {
              setRegisterUser({
                ...registerUser,
                confirmPassword: event.target.value,
              });
              handleError();
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle confirm password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownConfirmPassword}
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default SubmitPasswordForm;

const PasswordCriteria = styled(Typography)(({ theme, color }) => ({
  color: color ? 'green' : 'red',
  display: 'flex',
  alignItems: 'center',
  marginTop: theme.spacing(0.5),
}));
